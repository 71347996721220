import React, { Component } from "react";
import moment from "moment";
import TimeEntryMainPage from "./menuComponents/timeEntryMainPage";
import warningIcon from "../logo/warning.png";
import nextArrow from "../logo/next.png";
import backArrow from "../logo/back.png";

import {
  getMockUserTimeSheetData,
  getMockNonProjectTask,
  getMockUnassignedTasks,
  getMockProjectList,
  getMockDayWiseTotal,
} from "../utils/data";
import {
  getProjectList,
  getGlobalTasks,
  getUnassignedTasks,
  getErrorMessage,
  getDayWiseTotal,
  submitSaveDraftPatchMethod,
  submitSaveOrSubmitPatchMethod,
  getFreezeDateValue,
  saveOrSubmitPatchMethod,
  copyUnAssignTaskMethod,
  getEmployeeLeaveHolidayData,
} from "../utils/utils";
import Calender from "./menuComponents/commonComponents/calender";
import CheckInLoadingPopup from "./menuComponents/commonComponents/checkInLoadingPopup";
import TimeEntryRightPanel from "./menuComponents/timeEntryRightPanel";
import { fetchData, updateTaskDone } from "../utils/services";
import ErrorMessageModelOnSubmit from "./menuComponents/commonComponents/errorMessageModelOnSubmit";
import {
  inputValidation,
  inputValidationOnBlur,
  validation1,
} from "../utils/validations";
import { getAlerts } from "../utils/services";
import UnfreezeModal from "./menuComponents/commonComponents/unfreezeModal";
import { copyWeekMethod } from "../utils/utils";
import ResponseToast from "./menuComponents/commonComponents/responseToast";
import ErrorModel from "./menuComponents/commonComponents/errorModel";
import TaskDoneWarningModel from "./menuComponents/commonComponents/TaskDoneWarningModel";
import Feedback from "./utilComponents/feedback";
import Banner from "./utilComponents/banner";
import envConfig from "../environment-property/property.json";

class MainMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerVisible: envConfig.banner.showBanner,
      feedbackVisible: envConfig.showFeedback,
      error: {
        status: false,
        message: "",
      },
      errorModel: {
        openErrorModel: false,
        minimumHourExceedError: { error: false, errorList: [] },
        maximumHourExceedError: { error: false, errorList: [] },
        invoiceCommentMissingError: { error: false, errorList: [] },
      },
      errorMessage: [],
      error: {
        status: false,
        message: "",
      },
      loading: false,
      initialLoading: true,
      refresh: true,

      selectedDate: {
        // get and set current date, year and week
        firstDayofCurrentWeek: moment().startOf("isoweek").format("YYYY-MM-DD"),
        firstDayofWeekForUnfreeze: moment()
          .startOf("isoweek")
          .format("DD-MMM-YYYY"),
        year: moment().format("YYYY"),
        week: parseInt(moment().format("WW")),
        dateOfFirstDayofCurrentWeek: moment()
          .startOf("isoweek")
          .format("DD MMMM"),
        dateOfLastDayofCurrentWeek: moment().endOf("isoweek").format("DD MMMM"),
        days: this.createListofDays(
          moment().startOf("isoweek").format("YYYY-MM-DD")
        ),
        today: moment().format("YYYY-MM-DD"),
        currentWeek: parseInt(moment().format("WW")),
        currentDate: new Date(),
        maxNextNavigateDate: new Date(
          new Date().getTime() + 28 * 24 * 60 * 60 * 1000
        ),
        maxPastNavigateYear: parseInt(
          moment(new Date("2015")).format("YYYY-MM-DD")
        ),
      },
      sortedUserTimeSheetData: [],
      temp: [],
      userTimeSheetData: [],
      nonProjectTask: [],
      unassignedTasks: [],
      projectList: [],
      isCopyUnassigedTaskEnabled: false,
      selectedTaskValue: {},
      loggedUserGteEmployeeNo: 0,
      operationalUserGteEmployeeNo: 0,
      datePicker: "",
      rightPanelDivClassNameProperty: {
        alerts: {
          button: "accordion-button collapsed ",
          div: "accordion-collapse collapse",
        },
        addTask: {
          button: "accordion-button collapsed",
          div: "accordion-collapse collapse",
        },
        selectedTask: {
          button: "accordion-button",
          div: "accordion-collapse collapse show",
        },
      },
      projectListIds: [],
      alertData: null,
      saveDraft: false,
      openCalender: false,
      disableNextBtn: false,
      isHandleSaveDraftCalled: false,
      deleteTaskData: {},
      deleteGteProjectId: null,
      freezeDateData: {
        configType: "FREEZE_DATE",
        configValue: "",
        isFreezed: false,
        isUpdated: false,
      },
      /**
       * // ->[ required for -> the input elements of time slots ]
       *  ->[if clickInput=false -> total time validation fail (time should be less than 24)
       * -> [if fail -> not allow to click n focus to other inputs tags except already selected one]
       *  -> updated this value of clickInput => at onBlur and Onchange events of input elements
       * -> access the state of clickInput  => at onClick of input elements
       *        => on Task Level not allowed to update the state of selectedTaskValue And relevant isSelected value of the state of userTimeSheetData N selectedTaskValue
       *       [isSelected value -> required for ref vlues which use for select() N focus() of input element]
       */
      clickInput: true,
      unfreezeModal: false,
      response: false,
      callUnassignTask: false,
      responseMessage: "",
      showWarningIcon: true,
      isTaskDone: false,
    };
    this.lastRequestId = null;
    this.calendarRef = React.createRef();
  }

  componentDidMount() {
    this.getProjectList();
    this.getEmployeeLeaveHolidayList()
    this.setSelectedDelegateUser();
    this.getFreezeDate();
    document.addEventListener("mousedown", this.handleClickOutside);
    this.props.selectedDate(this.state.selectedDate);
    
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.freezeDateData?.isUpdated !==
      this.state.freezeDateData?.isUpdated
    ) {
      this.validateFreezeDate();
    }
    if (this.props.error !== prevProps.error) {
      this.setState({
        loading: false,
        initialLoading: false,
        error: this.props.error,
      });
    }
    if (
      this.state.operationalUserGteEmployeeNo !==
      this.props.operationalUserGteEmployeeNo
    ) {
      this.handleSaveDraft(
        false,
        false,
        this.state.userTimeSheetData,
        this.state.selectedDate.year,
        this.state.selectedDate.week,
        this.state.loggedUserGteEmployeeNo,
        this.state.operationalUserGteEmployeeNo
      );
      this.setSelectedDelegateUser();
      this.getProjectList();
      this.getEmployeeLeaveHolidayList();
    }
    if (prevState.datePicker !== this.state.datePicker) {
      this.setState({
        projectListIds: [],
      });
      this.selectedDatePicker();
      this.props.setAlerts(this.state.operationalUserGteEmployeeNo);
      this.setState({
        openCalender: false,
      });
    }
    //when timesheets change set callUnassignTask false
    if (prevState.userTimeSheetData !== this.state.userTimeSheetData) {
      this.setState({ callUnassignTask: false });
    }
    if (prevState.selectedDate.week !== this.state.selectedDate.week) {
      this.setState({
        refresh: true,
        projectListIds: [],
      });

      let reqDate = this.state.selectedDate.firstDayofCurrentWeek;
      let dateOfNextMonday = moment(reqDate).format("YYYY-MM-DD");
      //if maxDate < navigate date then disable next week button
      if (
        this.setDateAsNumber(this.getMaximumNavigateDate()) <=
        this.setDateAsNumber(new Date(dateOfNextMonday))
      ) {
        this.setState({ disableNextBtn: true });
      } else {
        this.setState({ disableNextBtn: false });
      }

      this.handleSaveDraft(
        false,
        false,
        this.state.userTimeSheetData,
        prevState.selectedDate.year,
        prevState.selectedDate.week,
        this.state.loggedUserGteEmployeeNo,
        this.state.operationalUserGteEmployeeNo
      );
      this.getFreezeDate();
      // const requestId = `REQUEST-${Date.now()}`;
      // this.lastRequestId = requestId;
      /**
       * set time delay to call  -> when click arrow keys for week changes
       */
      // setTimeout(() => {
      //   if (this.lastRequestId !== requestId) {
      //     return;
      //   } else {
      //     this.getProjectList();

      //     if (this.state.freezeDateData.isUpdated) {
      //       this.validateFreezeDate();
      //     }
      //   }
      // }, 2000);

      this.getProjectList();
      this.getEmployeeLeaveHolidayList();
      if (this.state.freezeDateData.isUpdated) {
        this.validateFreezeDate();
      }
    }
    if (
      this.state.projectList.length !== 0 &&
      prevState.projectList !== this.state.projectList
    ) {
      this.getProjectIds();
    }
    if (this.props.handleSaveDraft && !this.state.isHandleSaveDraftCalled) {
      this.handleSaveDraft(
        false,
        false,
        this.state.userTimeSheetData,
        this.state.selectedDate.year,
        this.state.selectedDate.week,
        this.state.loggedUserGteEmployeeNo,
        this.state.operationalUserGteEmployeeNo
      );
      this.setState({ isHandleSaveDraftCalled: true });
    }
    if (prevProps.clickedAlert !== this.props.clickedAlert) {
      this.handleNonProjectAlertButton();
    }
    if (
      this.state.selectedDate.firstDayofCurrentWeek !==
      prevState.selectedDate.firstDayofCurrentWeek
    ) {
      this.props.selectedDate(this.state.selectedDate);
    }
  }

  componentWillUnmount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (
      this.calendarRef.current &&
      !this.calendarRef.current.contains(event.target)
    ) {
      this.setState({ openCalender: false }); // Call the closeCalendar function from the parent
    }
  };
  setSelectedDelegateUser() {
    this.setState({
      gteEmployeeNo: this.props.operationalUserGteEmployeeNo,
      loggedUserGteEmployeeNo: this.props.loggedUserGteEmployeeNo,
      operationalUserGteEmployeeNo: this.props.operationalUserGteEmployeeNo,
    });
  }

  getNonProjectTask() {
    let data = getMockNonProjectTask();
    this.setState({ nonProjectTask: data });
  }

  getGlobalTasks() {
    let user = this.props.loggedUserDetails;
    if (!(user === null)) {
      getGlobalTasks("?is-global=", "Y")
        .then((response) => {
          this.setState({ nonProjectTask: response?.data });
        })
        .catch((error) => {
          let message = getErrorMessage(error);
          this.setState({
            loading: false,
            error: {
              status:
                message?.status === 401
                  ? false
                  : message?.status === 404
                  ? false
                  : true,
              message: message,
            },
          });
        });
    }
  }

  getEmployeeLeaveHolidayList() {
    if (
      this.props.loggedUserGteEmployeeNo > 0 &&
      this.props.operationalUserGteEmployeeNo > 0
    ) {
      getEmployeeLeaveHolidayData(
        "?gte-employee-no=",
        this.props.operationalUserGteEmployeeNo,
        "&year=",
        this.state.selectedDate.year,
        "&week-no=",
        this.state.selectedDate.week,
        "&logged-user-gte-employee-no=",
        this.props.loggedUserGteEmployeeNo
      )
        .then((response) => {
          
          let data = response?.data?.employeeLeaveHoliday?.weekData;
          let selectedDate = this.state.selectedDate;
          let days = this.state.selectedDate?.days?.map((day) => {
            return {
              ...day,
              isHoliday: data?.find((element) => element?.weekDate === day.date)
                ?.isHoliday,
              isLeave: data?.find((element) => element?.weekDate === day.date)
                ?.isLeave,
              // weekDate:data?.find((element) => element?.weekDate === day.date)?.weekDate
            };
          });

          this.setState({
            selectedDate: { ...selectedDate, days: days },
          });
        })
        .catch((error) => {
          let message = getErrorMessage(error);
          this.setState({
            loading: false,
            error: {
              status:
                message?.status === 401
                  ? false
                  : message?.status === 404
                  ? false
                  : true,
              message: message,
            },
            projectList: [],
            userTimeSheetData: [],
          });
        });
    }
  }

  getProjectList() {
    this.setState({
      loading: true,

      rightPanelDivClassNameProperty: {
        alerts: {
          button: "accordion-button collapsed ",
          div: "accordion-collapse collapse",
        },
        addTask: {
          button: "accordion-button collapsed",
          div: "accordion-collapse collapse",
        },
        selectedTask: {
          button: "accordion-button",
          div: "accordion-collapse collapse show",
        },
      },
    });
    if (
      this.props.loggedUserGteEmployeeNo > 0 &&
      this.props.operationalUserGteEmployeeNo > 0
    ) {
      getProjectList(
        "?gte-employee-no=",

        this.props.operationalUserGteEmployeeNo,
        "&year=",
        this.state.selectedDate.year,
        "&week-no=",
        this.state.selectedDate.week,
        "&logged-user-gte-employee-no=",
        this.props.loggedUserGteEmployeeNo
      )
        .then((response) => {
          this.setState({
            rightPanelDivClassNameProperty: {
              alerts: {
                button: "accordion-button collapsed ",
                div: "accordion-collapse collapse",
              },
              addTask: {
                button: "accordion-button collapsed",
                div: "accordion-collapse collapse",
              },
              selectedTask: {
                button: "accordion-button",
                div: "accordion-collapse collapse show",
              },
            },
            selectedTaskValue: {},
          });
          /**
           * {...project,isSelected:true} -> req fr project drop down checkboxes
           */
          let projectList = response?.data?.map((project) => {
            return { ...project, isSelected: true }; // initially all should be selected
          });
          this.setState({
            projectList: projectList.sort((a, b) => (a.name > b.name ? 1 : -1)),
            isCopyUnassigedTaskEnabled:
              projectList?.find(
                ({ isCopyUnassigedTaskEnabled }) =>
                  isCopyUnassigedTaskEnabled === "Y"
              ) && true,
          });

          this.props.setAlerts(this.props.operationalUserGteEmployeeNo);
          this.getUserTimeSheetData(response?.data);
        })
        .catch((error) => {
          let message = getErrorMessage(error);
          this.setState({
            loading: false,
            error: {
              status:
                message?.status === 401
                  ? false
                  : message?.status === 404
                  ? false
                  : true,
              message: message,
            },
            projectList: [],
            userTimeSheetData: [],
          });
        });
    }
  }

  getDayWiseTotal() {
    getDayWiseTotal(
      "?gte-employee-no=",
      this.props.operationalUserGteEmployeeNo,
      "&year=",
      this.state.selectedDate.year,
      "&week-no=",
      this.state.selectedDate.week,
      "&logged-user-gte-employee-no=",
      this.props.loggedUserGteEmployeeNo
    ).then((response) => {
      let data = response?.data;
      let selectedDate = this.state.selectedDate;
      let days = this.state.selectedDate?.days?.map((day) => {
        let timeTotal = 0;
        data?.map((daytotal) => {
          if (day.date === daytotal.date) {
            timeTotal = timeTotal + daytotal.dayTotal;
          }
        });
        return {
          ...day,
          timeTotal: Math.round((timeTotal + Number.EPSILON) * 100) / 100,
          timeError: parseFloat(timeTotal) > 24 ? true : false,
        };
      });

      this.setState({
        selectedDate: { ...selectedDate, days: days },
      });
    });
  }
  getFreezeDate() {
    getFreezeDateValue()
      .then((response) => {
        let freezeDateData = this.state.freezeDateData;
        freezeDateData.configValue = moment(
          response?.data[0]?.configValue
        ).format("YYYY-MM-DD");
        freezeDateData.isUpdated = true;

        this.setState({
          freezeDateData: freezeDateData,
        });
        if (this.state.freezeDateData.isUpdated) {
          this.validateFreezeDate();
        }
      })
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          loading: false,
          error: {
            status:
              message?.status === 401
                ? false
                : message?.status === 404
                ? false
                : true,
            message: message,
          },
        });
      });
  }
  validateFreezeDate = () => {
    let week = moment(this.state.freezeDateData?.configValue)
      .startOf("isoweek")
      .format("WW");
    let year = moment(this.state.freezeDateData?.configValue)
      .startOf("isoweek")
      .format("YYYY");

    let freezeDateData = this.state.freezeDateData;
    if (year === this.state.selectedDate.year) {
      if (
        parseInt(week) === parseInt(this.state.selectedDate.week) ||
        week > this.state.selectedDate.week
      ) {
        freezeDateData.isFreezed = true;
      }
      if (week < this.state.selectedDate.week) {
        freezeDateData.isFreezed = false;
      }
    }
    if (this.state.selectedDate.year < year) {
      freezeDateData.isFreezed = true;
    }
    if (this.state.selectedDate.year > year) {
      freezeDateData.isFreezed = false;
    }
    this.setState({
      freezeDateData: freezeDateData,
    });
  };

  updateSelectedTaskValue(selectedTaskValue) {
    this.setState({
      selectedTaskValue: selectedTaskValue,
    });
  }
  refreshTimeSheetData(
    selectedDate,
    operationalUserGteEmployeeNo,
    gteProjectId,
    updateAllSheet
  ) {
    this.setState({
      loading: true,
      saveDraft: false,
    });
    let promises = [];
    if (updateAllSheet) {
      this.state.userTimeSheetData?.map((sheet) => {
        promises.push(
          fetchData(
            sheet?.gteProjectId,
            this.state.selectedDate?.year,
            this.state.selectedDate?.week,
            this.state.operationalUserGteEmployeeNo,
            this.props.loggedUserGteEmployeeNo
          )
        );
      });
    } else {
      promises.push(
        fetchData(
          gteProjectId,
          selectedDate.year,
          selectedDate.week,
          operationalUserGteEmployeeNo,
          this.props.loggedUserGteEmployeeNo
        )
      );
    }

    if (promises?.length > 0) {
      this.readUserTimeSheetData(promises, updateAllSheet);
      this.getDayWiseTotal();
    }
  }
  readUserTimeSheetData(promises, updateAllSheet) {
    Promise.all(promises).then((promise) => {
      let newObjList = [];
      promise?.map((obj) => {
        if (
          obj.week === this.state.selectedDate.week &&
          obj.year === this.state.selectedDate.year
        ) {
          if (updateAllSheet) {
            let taskData = this.updateTaskDataList(
              obj.userTimeSheetData.taskData
            );

            newObjList.push({ ...obj.userTimeSheetData, taskData: taskData });
          } else {
            this.addTaskToTimeSheet(obj);
          }
        }
      });

      if (newObjList.length > 0) {
        this.updateUserTimeSheetDataAtSubmitOrSave(newObjList);
      }
    });
  }

  updateUserTimeSheetDataAtSubmitOrSave(newObjList) {
    let userTimeSheetDataS = this.state.userTimeSheetData;
    let userTimeSheetData = userTimeSheetDataS?.map((sheet) => {
      let taskData = [];
      newObjList?.map((obj) => {
        if (obj.gteProjectId === sheet.gteProjectId) {
          taskData = obj.taskData;
        }
      });

      return {
        ...sheet,
        taskData: taskData,
      };
    });

    this.updateStateOfTimeSheetDataList(userTimeSheetData);
    let selectedTaskValue = this.setSelectedTaskValue(userTimeSheetData);
    this.updateStateOfSelectedTaskValue(selectedTaskValue);
  }
  setSelectedTaskValue = (userTimeSheetData) => {
    let selectedTaskValue = this.state.selectedTaskValue;

    userTimeSheetData?.map((timeSheet) => {
      if (timeSheet.gteProjectId === selectedTaskValue.gteProjectId) {
        selectedTaskValue.timeSheet = timeSheet;

        timeSheet?.taskData?.map((taskdata) => {
          if (taskdata.taskId === selectedTaskValue.task.taskId) {
            selectedTaskValue.task = taskdata;

            taskdata?.timeDate?.map((timedate) => {
              if (timedate.date === selectedTaskValue.timedate.date) {
                selectedTaskValue.timedate = timedate;
              }
            });
          }
        });
      }
    });

    return selectedTaskValue;
  };
  addTaskToTimeSheet(newTimeSheetObj) {
    if (
      newTimeSheetObj.operationalUserGteEmployeeNo ===
      this.state.operationalUserGteEmployeeNo
    ) {
      let taskData = this.updateTaskDataList(
        newTimeSheetObj.userTimeSheetData.taskData
      );
      let userTimeSheetData = this.addTaskListToUserTimeSheet(
        taskData,
        newTimeSheetObj.userTimeSheetData.gteProjectId
      );
      this.updateStateOfTimeSheetDataList(userTimeSheetData);
    }
  }

  updateTaskDataList(taskData) {
    let currentWeekDaysList = this.state.selectedDate?.days;
    let istimeTypeEnableProject = false;
    let taskdata = taskData?.map((task) => {
      let totalTimeForTask = 0;
      let isTimeEntryStatusAsA = false;
      let timeDate = task?.timeDate?.map((timeentrydate) => {
        if (
          currentWeekDaysList.some(
            (currentWeekDaysList) =>
              currentWeekDaysList.date === timeentrydate.date
          )
        ) {
          totalTimeForTask = totalTimeForTask + timeentrydate.totalTime;
        }

        let timeEntry = timeentrydate?.timeEntry?.map((entry) => {
          if (entry.timeEntryStatus === "A" || entry.isFreezed === "Y") {
            isTimeEntryStatusAsA = true;
          }

          return {
            ...entry,
            timeError: false,
            isChanged: false,
            focused: false,
            initialTime: entry?.time,
            initialInvoiceComment: entry?.invoiceComment,
          };
        });

        if (timeentrydate.timeEntry?.length > 1) {
          istimeTypeEnableProject = true;
        }
        return {
          ...timeentrydate,
          timeError: false,
          istimeTypeEnableProject:
            timeentrydate.timeEntry?.length > 1 ? true : false,
          timeEntry: timeEntry.sort((a, b) =>
            a.timeTypeId > b.timeTypeId ? 1 : -1
          ),
        };
      });
      let estTimePersentage = this.calculateTimePercentage(
        task?.loggedHours,
        task?.estimatedHours
      );
      return {
        ...task,
        timeDate: timeDate,
        totalTimeForTask: totalTimeForTask,
        isTimeEntryStatusAsA: isTimeEntryStatusAsA,
        isRemoved: false,
        isChanged: false,
        isChangedWIPStatus: false,
        istimeTypeEnableProject: istimeTypeEnableProject,
        estTimePersentage: estTimePersentage,
      };
    });
    return taskdata;
  }

  addTaskListToUserTimeSheet(taskData, gteProjectId) {
    let userTimeSheetData = this.state.userTimeSheetData?.map((timeSheet) => {
      if (timeSheet.gteProjectId === gteProjectId) {
        return {
          ...timeSheet,
          taskData: taskData,
          display:
            timeSheet.gteProjectId === -1
              ? true
              : taskData?.length > 0
              ? true
              : false,

          viewOnLoad: true,
        };
      } else {
        return timeSheet;
      }
    });
    return userTimeSheetData;
  }

  getUserTimeSheetData = (projects) => {
    let promises = [];
    projects?.map((project) => {
      promises.push(
        fetchData(
          project.gteProjectId,
          this.state.selectedDate.year,
          this.state.selectedDate.week,
          this.props.operationalUserGteEmployeeNo,
          this.props.loggedUserGteEmployeeNo
        )
      );
    });
    this.getDayWiseTotal();
    this.readNFetchUserTimeSheetDataList(promises);
  };

  readNFetchUserTimeSheetDataList = (promises) => {
    Promise.all(promises).then((promise) => {
      let userTimeSheetData = [];
      promise?.map((obj) => {
        if (
          obj.week === this.state.selectedDate.week &&
          obj.year === this.state.selectedDate.year
        ) {
          userTimeSheetData = [...userTimeSheetData, obj.userTimeSheetData];
        }
      });
      if (userTimeSheetData.length > 0) {
        // // set project name alphabet order

        const sortedUserTimeSheetData = userTimeSheetData
          .sort((a, b) => (a.projectName > b.projectName ? 1 : -1))
          .sort((a, b) =>
            a.gteProjectId !== -1 && b.gteProjectId === -1 ? -1 : 0
          );

        this.updateInitialTimeSheetDataList(sortedUserTimeSheetData);
      }
    });
  };

  getProjectIds() {
    const projectIds = this.state.projectList
      ?.map((project) => project.gteProjectId)
      .filter((projectId) => projectId !== -1)
      .join(",");
    this.setState({ projectListIds: projectIds });
  }

  /**
   * calculate and add the value of  totalTimeForTask of week
   * @param {*} userTimeSheetData
   */
  updateInitialTimeSheetDataList(userTimeSheetData) {
    let currentWeekDaysList = this.state.selectedDate?.days;

    userTimeSheetData = userTimeSheetData?.map((project) => {
      /**
       *  if all task's task?.timeSheetRecordStatus === "H"  -> this project hide in timesheet [should not display in timesheet]
       *  But For Virtusa NON Project [gteProjectId === -1] -> it should not be applicable [Virtusa Non Project should display regardless of task exist or not]
       */

      let isAllTasksAsTimeSheetRecordStatus_H = true;
      let taskData = project.taskData?.map((task) => {
        let totalTimeForTask = 0;
        let isTimeEntryStatusAsA = false;
        let istimeTypeEnableProject = false;

        // if exist one of timeSheetRecordStatus !== "H" ->this project display in timesheet
        if (!(task?.timeSheetRecordStatus === "H")) {
          isAllTasksAsTimeSheetRecordStatus_H = false;
        }

        let timeDate = task?.timeDate?.map((timeentrydate) => {
          if (
            currentWeekDaysList.some(
              (currentWeekDaysList) =>
                currentWeekDaysList.date === timeentrydate.date
            )
          ) {
            totalTimeForTask = totalTimeForTask + timeentrydate.totalTime;
          }

          let timeEntry = timeentrydate?.timeEntry?.map((entry) => {
            if (entry.timeEntryStatus === "A" || entry.isFreezed === "Y") {
              isTimeEntryStatusAsA = true;
            } //required for TRASH icon appear on time sheet
            return {
              ...entry,
              timeError: false,
              isChanged: false,
              isSelected: false,
              focused: false,
              initialTime: entry?.time,
              initialInvoiceComment: entry?.invoiceComment,
            };
          });

          if (timeentrydate.timeEntry?.length > 1) {
            istimeTypeEnableProject = true;
          }

          return {
            ...timeentrydate,
            timeError: false,
            istimeTypeEnableProject:
              timeentrydate.timeEntry?.length > 1 ? true : false,
            timeEntry: timeEntry.sort((a, b) =>
              a.timeTypeId > b.timeTypeId ? 1 : -1
            ),
            isSelected: false,
          };
        });
        let estTimePersentage = this.calculateTimePercentage(
          task?.loggedHours,
          task?.estimatedHours
        );
        return {
          ...task,
          timeDate: timeDate,
          totalTimeForTask: totalTimeForTask,
          isTimeEntryStatusAsA: isTimeEntryStatusAsA,
          isRemoved: false,
          isChanged: false,
          isChangedWIPStatus: false,
          istimeTypeEnableProject: istimeTypeEnableProject,
          estTimePersentage: estTimePersentage,
        };
      });

      return {
        ...project,
        taskData: taskData,

        isViewed: true,
        isChanged: false,
        viewOnLoad:
          taskData?.length > 0
            ? true
            : project.gteProjectId === -1
            ? true
            : false,
        display:
          project.gteProjectId === -1
            ? true
            : taskData?.length > 0
            ? isAllTasksAsTimeSheetRecordStatus_H
              ? false
              : true
            : false,
      };
    });

    this.setState({
      userTimeSheetData: userTimeSheetData,
      loading: false,
      initialLoading: false,
    });
  }
  calculateTimePercentage(loggedHours, estimatedHours) {
    let value = 0;
    // (loggedHours / estimatedHours) * 100
    if (estimatedHours > 0) {
      value = (loggedHours / estimatedHours) * 100;
      if (value > 100) {
        value = 100;
      }
    }
    if (estimatedHours === 0) {
      value = 0;
    }
    value = Math.round((value + Number.EPSILON) * 100) / 100;
    return parseFloat(value);
  }
  selectedDatePicker = () => {
    let selectedDate = this.state.selectedDate;
    let reqDate = this.state.datePicker;

    // console.log("reqDate day  ",moment(reqDate)
    // .format("dddd"),)

    // console.log("reqDate date ",moment(reqDate)
    // .format("YYYY-MM-DD"),)
    /**
     * date picker take the previous date as picked date
     * for get accurate date: add one day for picked up date
     */
    reqDate = moment(reqDate).add(1, "days").format("YYYY-MM-DD");

    this.setState({
      selectedDate: {
        ...selectedDate,
        today: moment().format("YYYY-MM-DD"),
        firstDayofCurrentWeek: moment(reqDate)
          .startOf("isoweek")
          .format("YYYY-MM-DD"),
        firstDayofWeekForUnfreeze: moment(reqDate)
          .startOf("isoweek")
          .format("DD-MMM-YYYY"),
        year: moment(reqDate).startOf("isoweek").format("YYYY"),
        week: parseInt(moment(reqDate).startOf("isoweek").format("WW")),

        dateOfFirstDayofCurrentWeek: moment(reqDate)
          .startOf("isoweek")
          .format("D MMMM"),
        dateOfLastDayofCurrentWeek: moment(reqDate)
          .endOf("isoweek")
          .format("D MMMM"),
        days: this.createListofDays(
          moment(reqDate).startOf("isoweek").format("YYYY-MM-DD")
        ),
      },
      selectedTaskTab_class: "accordion-collapse collapse show",
    });
  };

  timSlotValueUpdate(value, gteProjectId, task, timedate, type) {
    value = value.replace(/[^0-9.]/g, "");
    value = inputValidation(value, 2);
    if (type === "timeValidation") {
      value = inputValidationOnBlur(value);
    }
    let time = value === "" ? 0 : parseFloat(value);

    if (time > 24) {
      this.setState({
        clickInput: false,
      });
    } else {
      this.setState({
        clickInput: true,
      });
    }

    let userTimeSheetDataS = this.state.userTimeSheetData;
    let selectedTaskValue = this.state.selectedTaskValue;
    let userTimeSheetData = userTimeSheetDataS?.map((project) => {
      if (project.gteProjectId === gteProjectId) {
        let taskData = project.taskData?.map((taskdata) => {
          let totalTimeForTask = 0;
          if (taskdata?.taskAssignmentId === task?.taskAssignmentId) {
            let timeDate = taskdata?.timeDate?.map((timeentrydate) => {
              if (timeentrydate.date === timedate.date) {
                totalTimeForTask = totalTimeForTask + parseFloat(time);
                this.updateDayWiseTotal(
                  timeentrydate.date,
                  timeentrydate.totalTime === ""
                    ? 0
                    : parseFloat(timeentrydate.totalTime),
                  time
                );
                let timeEntry;
                if (!timeentrydate?.istimeTypeEnableProject) {
                  timeEntry = timeentrydate?.timeEntry?.map((timeEntryObj) => {
                    if (type === "timeChange") {
                      return {
                        ...timeEntryObj,
                        time: time,
                        // isChanged: true,
                        isChanged: true,
                        focused: true,
                        timeError:
                          value === "" ? false : value > 24 ? true : false,
                      };
                    }
                    if (type === "timeValidation") {
                      return {
                        ...timeEntryObj,
                        time: time,
                        isChanged: true,
                        focused: false,
                        timeError:
                          value === "" ? false : value > 24 ? true : false,
                      };
                    }
                  });
                } else {
                  timeEntry = timeentrydate?.timeEntry;
                }

                if (type === "timeChange") {
                  return {
                    ...timeentrydate,
                    totalTime: value, //--> we cannot update 0 for "" value -> bcz this value take as time slot's input element's value
                    timeError:
                      !timeentrydate?.istimeTypeEnableProject &&
                      (value === "" ? false : value > 24 ? true : false),
                    isChanged: true,
                    timeEntry: timeEntry.sort((a, b) =>
                      a.timeTypeId > b.timeTypeId ? 1 : -1
                    ),
                    isSelected: false,
                  };
                }
                if (type === "timeValidation") {
                  return {
                    ...timeentrydate,
                    totalTime: value === "" ? 0 : value,
                    timeError: timeentrydate?.istimeTypeEnableProject
                      ? false
                      : value === ""
                      ? false
                      : value > 24
                      ? true
                      : false,
                    timeEntry: timeEntry.sort((a, b) =>
                      a.timeTypeId > b.timeTypeId ? 1 : -1
                    ),
                    isSelected: false,
                  };
                }
              } else {
                let time =
                  timeentrydate.totalTime === "" ? 0 : timeentrydate.totalTime;
                totalTimeForTask = totalTimeForTask + parseFloat(time);
                return { ...timeentrydate };
              }
            });
            let loggedHours =
              taskdata?.loggedHours - taskdata?.totalTimeForTask;
            loggedHours = loggedHours + totalTimeForTask;
            loggedHours = this.formatNumberToTwoDecimals(loggedHours);
            let estTimePersentage = this.calculateTimePercentage(
              loggedHours,
              task?.estimatedHours
            );
            if (type === "timeChange") {
              selectedTaskValue.task = {
                ...taskdata,
                timeDate: timeDate,
                totalTimeForTask: totalTimeForTask,
                isChanged: true,
                loggedHours: loggedHours,
                estTimePersentage: estTimePersentage,
              };
              return {
                ...taskdata,
                timeDate: timeDate,
                totalTimeForTask: totalTimeForTask,
                isChanged: true,
                loggedHours: loggedHours,
                estTimePersentage: estTimePersentage,
              };
            }
            if (type === "timeValidation") {
              selectedTaskValue.task = {
                ...taskdata,
                timeDate: timeDate,
                totalTimeForTask: totalTimeForTask,
                loggedHours: loggedHours,
                estTimePersentage: estTimePersentage,
              };
              return {
                ...taskdata,
                timeDate: timeDate,
                totalTimeForTask: totalTimeForTask,
                loggedHours: loggedHours,
                estTimePersentage: estTimePersentage,
              };
            }
          } else {
            return taskdata;
          }
        });
        if (type === "timeChange") {
          return { ...project, taskData: taskData, isChanged: true };
        }
        if (type === "timeValidation") {
          return { ...project, taskData: taskData };
        }
      } else {
        return project;
      }
    });
    this.setState({
      userTimeSheetData: userTimeSheetData,
      saveDraft: true,
      selectedTaskValue: selectedTaskValue,
    });
  }

  /**
   * timeType_timSlotValueUpdate() Method
   *   -> here update state of "userTimeSheetData" list and "selectedTaskValue"
   *      which are used in timesheet panel and Selected task Panel
   *  -> within timeType_timSlotValueUpdate() ->
   *        -> call updateDayWiseTotal() method -> to update total time of day ->selected date
   *
   */
  timeType_timSlotValueUpdate(
    value,
    selectedTaskValue,
    entry,
    timeError,
    type
  ) {
    if (!timeError) {
      timeError = value !== "" && value > 24 ? true : false;
    }
    let time = value === "" ? 0 : parseFloat(value);

    if (timeError) {
      this.setState({
        clickInput: false,
      });
    } else {
      this.setState({
        clickInput: true,
      });
    }
    let userTimeSheetDataExit = this.state.userTimeSheetData;

    let userTimeSheetData = userTimeSheetDataExit?.map((project) => {
      if (project.gteProjectId === selectedTaskValue?.timeSheet?.gteProjectId) {
        let taskData = project?.taskData?.map((taskdata) => {
          let totalTimeForTask = 0;
          if (taskdata.taskId === selectedTaskValue.task.taskId) {
            let timeDate = taskdata?.timeDate?.map((timedate) => {
              if (timedate.date === selectedTaskValue.timedate.date) {
                let totalTime = 0;
                let timeEntry = timedate?.timeEntry?.map((timeentry) => {
                  if (timeentry.timeEntryId === entry.timeEntryId) {
                    let newTime = value === "" ? 0 : parseFloat(value);
                    totalTime = totalTime + newTime;
                    totalTimeForTask = totalTimeForTask + newTime;
                    this.updateDayWiseTotal(
                      timedate.date,
                      timeentry.time === "" ? 0 : parseFloat(timeentry.time),
                      value === "" ? 0 : parseFloat(value)
                    );

                    if (type === "timeChange") {
                      return {
                        ...timeentry,
                        isChanged: true,
                        time: value,
                        timeError: timeError,
                        isSelected: false,
                        focused: true,
                      };
                    }
                    if (type === "timeValidation") {
                      return {
                        ...timeentry,
                        isChanged: true,
                        time: value,
                        timeError: timeError,
                        isSelected: false,
                        focused: false,
                      };
                    }
                  } else {
                    totalTime = totalTime + parseFloat(timeentry.time);
                    totalTimeForTask =
                      totalTimeForTask + parseFloat(timeentry.time);
                    return { ...timeentry, isSelected: false };
                  }
                });

                selectedTaskValue.newTimetotal = totalTime;
                selectedTaskValue.timedate = {
                  ...timedate,
                  isChanged: true,
                  timeEntry: timeEntry.sort((a, b) =>
                    a.timeTypeId > b.timeTypeId ? 1 : -1
                  ),
                  isSelected: false,
                };
                return {
                  ...timedate,
                  isSelected: false,
                  isChanged: true,
                  timeEntry: timeEntry.sort((a, b) =>
                    a.timeTypeId > b.timeTypeId ? 1 : -1
                  ),
                  totalTime:
                    Math.round((totalTime + Number.EPSILON) * 100) / 100,
                };
              } else {
                let time = timedate.totalTime === "" ? 0 : timedate.totalTime;
                totalTimeForTask = totalTimeForTask + parseFloat(time);
                return timedate;
              }
            });

            let loggedHours =
              parseFloat(taskdata?.loggedHours) -
              parseFloat(taskdata?.totalTimeForTask);
            loggedHours = loggedHours + parseFloat(totalTimeForTask);
            loggedHours = this.formatNumberToTwoDecimals(loggedHours);
            let estTimePersentage = this.calculateTimePercentage(
              loggedHours,
              taskdata?.estimatedHours
            );
            selectedTaskValue.task = {
              ...taskdata,
              timeDate: timeDate,
              isSelected: false,
              totalTimeForTask: totalTimeForTask,
              isChanged: true,
              loggedHours: loggedHours,
              estTimePersentage: estTimePersentage,
            };

            return {
              ...taskdata,
              timeDate: timeDate,
              isSelected: false,
              totalTimeForTask: totalTimeForTask,
              isChanged: true,
              loggedHours: loggedHours,
              estTimePersentage: estTimePersentage,
            };
          } else {
            return taskdata;
          }
        });

        selectedTaskValue.timeSheet = {
          ...project,
          taskData: taskData,
          isChanged: true,
        };
        return { ...project, taskData: taskData, isChanged: true };
      } else {
        return project;
      }
    });
    this.setState({
      saveDraft: true,
      userTimeSheetData: userTimeSheetData,
      selectedTaskValue: selectedTaskValue,
    });
  }

  addTimeEntered_TimeType = (value, selectedTaskValue, entry, timeError) => {
    value = value.replace(/[^0-9.]/g, "");
    value = inputValidation(value, 2);

    this.timeType_timSlotValueUpdate(
      value,
      selectedTaskValue,
      entry,
      timeError,
      "timeChange"
    );
  };

  validateTimeEntered_TimeType = (value, selectedTaskValue, entry) => {
    value = inputValidationOnBlur(value);

    let time = value === "" ? 0 : parseFloat(value);
    let errorStatus =
      this.state.selectedTaskValue?.newTimetotal > 24 ? true : false;

    if (errorStatus) {
      this.setState({
        clickInput: false,
      });
    } else {
      this.setState({
        clickInput: true,
      });
    }

    this.timeType_timSlotValueUpdate(
      time,
      selectedTaskValue,
      entry,
      errorStatus,
      "timeValidation"
    );
  };

  // updateUserTimeSheetDataNSelectedTaskOfTimeTypeProject(
  //   value,
  //   selectedTaskValue,
  //   entry,
  //   timeError
  // ) {
  //   if (!timeError) {
  //     timeError = value !== "" && value > 24 ? true : false;
  //   }

  //   if (timeError) {
  //     this.setState({
  //       clickInput: false,
  //     });
  //   } else {
  //     this.setState({
  //       clickInput: true,
  //     });
  //   }

  //   let userTimeSheetDataExit = this.state.userTimeSheetData;

  //   let userTimeSheetData = userTimeSheetDataExit?.map((project) => {
  //     if (project.gteProjectId === selectedTaskValue?.timeSheet?.gteProjectId) {
  //       let taskData = project?.taskData?.map((taskdata) => {
  //         if (taskdata.taskId === selectedTaskValue.task.taskId) {
  //           let timeDate = taskdata?.timeDate?.map((timedate) => {
  //             if (timedate.date === selectedTaskValue.timedate.date) {
  //               let totalTime = 0;
  //               let timeEntry = timedate?.timeEntry?.map((timeentry) => {
  //                 if (timeentry.timeEntryId === entry.timeEntryId) {
  //                   let newTime = value === "" ? 0 : parseFloat(value);
  //                   totalTime = totalTime + newTime;
  //                   this.updateDayWiseTotal(
  //                     timedate.date,
  //                     timeentry.time === "" ? 0 : parseFloat(timeentry.time),
  //                     value === "" ? 0 : parseFloat(value)
  //                   );

  //                   return {
  //                     ...timeentry,
  //                     isChanged: true,
  //                     time: value,
  //                     timeError: timeError,
  //                     isSelected: false,
  //                   };
  //                 } else {
  //                   totalTime = totalTime + parseFloat(timeentry.time);
  //                   return { ...timeentry, isSelected: false };
  //                 }
  //               });

  //               selectedTaskValue.newTimetotal = totalTime;
  //               selectedTaskValue.timedate = {
  //                 ...timedate,
  //                 isChanged: true,
  //                 timeEntry: timeEntry.sort((a, b) =>
  //                   a.timeTypeId > b.timeTypeId ? 1 : -1
  //                 ),
  //                 isSelected: false,
  //               };
  //               return {
  //                 ...timedate,
  //                 isSelected: false,
  //                 isChanged: true,
  //                 timeEntry: timeEntry.sort((a, b) =>
  //                   a.timeTypeId > b.timeTypeId ? 1 : -1
  //                 ),
  //                 totalTime:
  //                   Math.round((totalTime + Number.EPSILON) * 100) / 100,
  //               };
  //             } else {
  //               return timedate;
  //             }
  //           });

  //           selectedTaskValue.task = {
  //             ...taskdata,
  //             timeDate: timeDate,
  //             isSelected: false,
  //             isChanged: true,
  //           };
  //           return {
  //             ...taskdata,
  //             timeDate: timeDate,
  //             isSelected: false,
  //             isChanged: true,
  //           };
  //         } else {
  //           return taskdata;
  //         }
  //       });

  //       selectedTaskValue.timeSheet = {
  //         ...project,
  //         taskData: taskData,
  //         isChanged: true,
  //       };
  //       return { ...project, taskData: taskData, isChanged: true };
  //     } else {
  //       return project;
  //     }
  //   });
  //   this.setState({
  //     saveDraft: true,
  //     userTimeSheetData: userTimeSheetData,
  //     selectedTaskValue: selectedTaskValue,
  //   });
  // }

  highlightSelectedInputOfTimeType(selectedTaskValue, entry) {
    if (this.state.clickInput) {
      let userTimeSheetDataExit = this.state.userTimeSheetData;
      let userTimeSheetData = userTimeSheetDataExit?.map((project) => {
        if (
          project.gteProjectId === selectedTaskValue?.timeSheet?.gteProjectId
        ) {
          let taskData = project?.taskData?.map((taskdata) => {
            if (taskdata.taskId === selectedTaskValue.task.taskId) {
              let timeDate = taskdata?.timeDate?.map((timedate) => {
                if (timedate.date === selectedTaskValue.timedate.date) {
                  let timeEntry = timedate?.timeEntry?.map((timeentry) => {
                    if (timeentry.timeEntryId === entry.timeEntryId) {
                      return {
                        ...timeentry,
                        isSelected: true,
                      };
                    } else {
                      return { ...timeentry, isSelected: false };
                    }
                  });

                  selectedTaskValue.timedate = {
                    ...timedate,
                    timeEntry: timeEntry.sort((a, b) =>
                      a.timeTypeId > b.timeTypeId ? 1 : -1
                    ),
                    isSelected: false,
                  };
                  return {
                    ...timedate,
                    isSelected: false,

                    timeEntry: timeEntry.sort((a, b) =>
                      a.timeTypeId > b.timeTypeId ? 1 : -1
                    ),
                  };
                } else {
                  return timedate;
                }
              });

              selectedTaskValue.task = {
                ...taskdata,
                timeDate: timeDate,
                isSelected: false,
              };
              return {
                ...taskdata,
                timeDate: timeDate,
                isSelected: false,
              };
            } else {
              return taskdata;
            }
          });

          selectedTaskValue.timeSheet = {
            ...project,
            taskData: taskData,
          };
          return { ...project, taskData: taskData };
        } else {
          return project;
        }
      });
      this.setState({
        saveDraft: true,
        userTimeSheetData: userTimeSheetData,
        selectedTaskValue: selectedTaskValue,
      });
    }
  }

  enterInvoiceCommentOfTimeTypeProject = (value, selectedTaskValue, entry) => {
    let userTimeSheetDataExit = this.state.userTimeSheetData;

    let userTimeSheetData = userTimeSheetDataExit?.map((project) => {
      if (project.gteProjectId === selectedTaskValue?.timeSheet?.gteProjectId) {
        let taskData = project?.taskData?.map((taskdata) => {
          if (taskdata.taskId === selectedTaskValue.task.taskId) {
            let timeDate = taskdata?.timeDate?.map((timedate) => {
              if (timedate.date === selectedTaskValue.timedate.date) {
                let timeEntry = timedate?.timeEntry?.map((timeentry) => {
                  if (timeentry.timeEntryId === entry.timeEntryId) {
                    return {
                      ...timeentry,
                      invoiceComment: value,
                      isChanged: true,
                    };
                  } else {
                    return timeentry;
                  }
                });

                selectedTaskValue.timedate = {
                  ...timedate,
                  isChanged: true,
                  timeEntry: timeEntry.sort((a, b) =>
                    a.timeTypeId > b.timeTypeId ? 1 : -1
                  ),
                };
                return {
                  ...timedate,
                  isChanged: true,
                  timeEntry: timeEntry.sort((a, b) =>
                    a.timeTypeId > b.timeTypeId ? 1 : -1
                  ),
                };
              } else {
                return timedate;
              }
            });

            selectedTaskValue.task = {
              ...taskdata,
              timeDate: timeDate,
              isChanged: true,
            };
            return {
              ...taskdata,
              timeDate: timeDate,
              isSelected: false,
              isChanged: true,
            };
          } else {
            return taskdata;
          }
        });

        selectedTaskValue.timeSheet = {
          ...project,
          taskData: taskData,
          isChanged: true,
        };
        return { ...project, taskData: taskData, isChanged: true };
      } else {
        return project;
      }
    });
    this.setState({
      saveDraft: true,
      userTimeSheetData: userTimeSheetData,
      selectedTaskValue: selectedTaskValue,
    });
  };

  /**
   * update "selectedTaskValue" state ->
   * when click time slot of time Type Enable project -> required to display
   *      selected time slots' data in Selected Task Panel
   *
   */
  setSelectedTaskPanelValue = (timeSheet, gteProjectId, task, timedate) => {
    if (this.state.clickInput) {
      let rightPanelDivClassNameProperty =
        this.state.rightPanelDivClassNameProperty;
      rightPanelDivClassNameProperty.alerts = {
        button: "accordion-button collapsed",
        div: "accordion-collapse collapse",
      };
      rightPanelDivClassNameProperty.addTask = {
        button: "accordion-button collapsed",
        div: "accordion-collapse collapse",
      };
      rightPanelDivClassNameProperty.selectedTask = {
        button: "accordion-button",
        div: "accordion-collapse collapse show",
      };

      this.setState({
        selectedTaskValue: {
          gteProjectId: 0,
          timeSheet: timeSheet,
          task: {},
          timedate: {},
          newTimetotal: 0,
          refresh: false,
        },
      });
      this.setState({
        selectedTaskValue: {
          gteProjectId: gteProjectId,
          timeSheet: timeSheet,
          task: task,
          timedate: timedate,
          newTimetotal: 0,
          refresh: true,
        },
        rightPanelDivClassNameProperty: rightPanelDivClassNameProperty,
      });
    }
  };

  updateStateOfTimeSheetDataList = (userTimeSheetData) => {
    const sortedUserTimeSheetData = userTimeSheetData
      .sort((a, b) => (a.projectName > b.projectName ? 1 : -1))
      .sort((a, b) =>
        a.gteProjectId !== -1 && b.gteProjectId === -1 ? -1 : 0
      );
    this.setState({
      userTimeSheetData: sortedUserTimeSheetData,
      saveDraft: true,
      loading: false,
    });
  };
  updateStateOfSelectedTaskValue = (selectedTaskValue) => {
    this.setState({
      selectedTaskValue: selectedTaskValue,
    });
  };

  updateDayWiseTotal = (date, savedTime, newTime) => {
    let selectedDate = this.state.selectedDate;
    let days = this.state.selectedDate?.days?.map((day) => {
      if (day.date === date) {
        let exitTimeTotal = parseFloat(day.timeTotal);

        let time = exitTimeTotal - savedTime;
        let timeTotal = time + parseFloat(newTime);

        return {
          ...day,
          timeTotal: Math.round((timeTotal + Number.EPSILON) * 100) / 100,
          timeError: parseFloat(timeTotal) > 24 ? true : false,
        };
      } else {
        return day;
      }
    });

    this.setState({
      selectedDate: { ...selectedDate, days: days },
    });
  };
  /**
   * pastWeek, nextWeek -> Update state of selectedDate -> When -> click arrow keys -> for change week
   */

  pastWeek = () => {
    let selectedDate = this.state.selectedDate;
    let reqDate = this.state.selectedDate.firstDayofCurrentWeek;
    let dateOfLastMondy = moment(reqDate)
      .subtract(7, "days")
      .format("YYYY-MM-DD");

    this.setState({
      loading: true,
      datePicker: dateOfLastMondy,
      selectedDate: {
        ...selectedDate,
        today: moment().format("YYYY-MM-DD"),
        firstDayofCurrentWeek: dateOfLastMondy,
        firstDayofWeekForUnfreeze: moment(dateOfLastMondy)
          .startOf("isoweek")
          .format("DD-MMM-YYYY"),
        year: moment(dateOfLastMondy).startOf("isoweek").format("YYYY"),
        week: parseInt(moment(dateOfLastMondy).startOf("isoweek").format("WW")),

        dateOfFirstDayofCurrentWeek: moment(dateOfLastMondy)
          .startOf("isoweek")
          .format("D MMMM"),
        dateOfLastDayofCurrentWeek: moment(dateOfLastMondy)
          .endOf("isoweek")
          .format("D MMMM"),

        days: this.createListofDays(dateOfLastMondy),
      },
    });
  };

  setDateAsNumber(inputDate) {
    const year = inputDate.getFullYear();
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based, so add 1
    const day = inputDate.getDate().toString().padStart(2, "0");

    const formattedDate = `${year}${month}${day}`;

    return formattedDate;
  }
  getMaximumNavigateDate() {
    const now = new Date();
    const currentDay = now.getDay();
    const daysToMonday = currentDay === 0 ? 6 : 1 - currentDay; // Calculate days to Monday
    const mondayDate = new Date(now);
    mondayDate.setDate(now.getDate() + daysToMonday); // Set to the nearest Monday

    mondayDate.setDate(mondayDate.getDate() + 28);
    return mondayDate;
  }

  nextWeek = () => {
    let selectedDate = this.state.selectedDate;
    let reqDate = this.state.selectedDate.firstDayofCurrentWeek;

    let dateOfNextMonday = moment(reqDate).add(7, "days").format("YYYY-MM-DD");

    this.setState({
      loading: true,
      datePicker: dateOfNextMonday,
      selectedDate: {
        ...selectedDate,
        today: moment().format("YYYY-MM-DD"),
        firstDayofCurrentWeek: dateOfNextMonday,
        firstDayofWeekForUnfreeze: moment(dateOfNextMonday)
          .startOf("isoweek")
          .format("DD-MMM-YYYY"),
        year: moment(dateOfNextMonday).startOf("isoweek").format("YYYY"),
        week: parseInt(
          moment(dateOfNextMonday).startOf("isoweek").format("WW")
        ),

        dateOfFirstDayofCurrentWeek: moment(dateOfNextMonday)
          .startOf("isoweek")
          .format("D MMMM"),
        dateOfLastDayofCurrentWeek: moment(dateOfNextMonday)
          .endOf("isoweek")
          .format("D MMMM"),
        days: this.createListofDays(dateOfNextMonday),
      },
    });
  };
  createListofDays(firstDayofCurrentWeek) {
    let days = [];
    for (let i = 0; i < 7; i++) {
      days.push({
        day: i,
        date: moment(firstDayofCurrentWeek).add(i, "days").format("YYYY-MM-DD"),
        timeTotal: 0,
        isSelected: 1,
        isHoliday: "",
        isLeave: "",
      });
    }
    return days;
  }

  getModifiedTimeListAndWIPDoneStatus = (userTimeSheetData) => {
    let modifiedTime = [];
    let anyWipDoneChanges = false;
    userTimeSheetData?.map((timeSheet) => {
      if (timeSheet.isChanged) {
        timeSheet?.taskData?.map((taskDataObj) => {
          if (taskDataObj.isChanged) {
            let timeEntry = [];
            taskDataObj?.timeDate?.map((timeDateObj) => {
              timeDateObj?.timeEntry?.map((timeEntryObj) => {
                if (
                  (timeEntryObj.isChanged &&
                    timeEntryObj?.time !== timeEntryObj?.initialTime) ||
                  timeEntryObj?.initialInvoiceComment !==
                    timeEntryObj?.invoiceComment
                ) {
                  timeEntry.push({
                    timeEntryId: timeEntryObj.timeEntryId,
                    time: timeEntryObj?.timeError
                      ? 0
                      : parseFloat(timeEntryObj?.time),
                    invoiceComment: timeEntryObj?.invoiceComment,
                  });
                  if (
                    taskDataObj.isChangedWIPStatus &&
                    timeEntryObj?.time !== timeEntryObj?.initialTime
                  ) {
                    anyWipDoneChanges = true;
                  }
                }
              });
            });
            if (timeEntry?.length > 0) {
              modifiedTime.push({
                timeSheetRecordId: taskDataObj.timeSheetRecordId,
                timeEntry: timeEntry,
              });
            }
          }
        });
      }
    });
    return { modifiedTime: modifiedTime, anyWipDoneChanges: anyWipDoneChanges };
  };

  saveWIPDoneValueChanges = (userTimeSheetData) => {
    let promises = [];
    let status = false;
    userTimeSheetData?.map((timeSheet) => {
      if (timeSheet.isChanged) {
        timeSheet?.taskData?.map((taskDataObj) => {
          if (taskDataObj.isChanged) {
            if (taskDataObj.isChangedWIPStatus) {
              let value = {
                userStatus:
                  taskDataObj?.taskAssignmentStatus === "WIP"
                    ? "I"
                    : taskDataObj?.taskAssignmentStatus === "Done" && "R",
                lastUpdatedBy: this.props.loggedUserGteEmployeeNo,
                attribute15: "W-TE-SC",
              };
              promises.push(
                updateTaskDone(taskDataObj.taskAssignmentId, value)
              );
            }
          }
        });
      }
    });

    this.getResponseAndDoRefresh(promises);

    // status && this.refreshTimeSheetData({}, 0, 0, true);

    // return true;
  };

  getResponseAndDoRefresh(promises) {
    Promise.all(promises).then((promise) => {
      let responseArray = [];
      promise?.map((obj) => {
        responseArray = [
          ...responseArray,
          { taskAssignmentId: obj?.taskAssignmentId },
        ];
        //  console.log(obj?.taskAssignmentId,"-", obj?.response)
      });
      if (responseArray?.length === promises?.length) {
        // console.log(responseArray?.length,"-",promises?.length)
        this.refreshTimeSheetData({}, 0, 0, true);
      }
    });
  }
  handleSaveDraft = (
    saveDraftState,
    submitState,
    userTimeSheetData,
    year,
    week,
    loggedUserGteEmployeeNo,
    operationalUserGteEmployeeNo
  ) => {
    if (userTimeSheetData?.length > 0 && this.state.saveDraft) {
      let modifiedTimeListAndWIPDoneStatus =
        this.getModifiedTimeListAndWIPDoneStatus(userTimeSheetData);
      let modifiedTime = modifiedTimeListAndWIPDoneStatus?.modifiedTime;

      this.setState({
        loading: true,
      });
      // this.saveWIPDoneValueChanges(userTimeSheetData);
      this.saveDraft(
        {
          identification: "WU/S",
          loggedUserGteEmployeeId: parseInt(loggedUserGteEmployeeNo),
          operationalUserGteEmployeeId: parseInt(operationalUserGteEmployeeNo),
          modifiedTime: modifiedTime,
        },
        year,
        week,
        saveDraftState,
        userTimeSheetData,
        operationalUserGteEmployeeNo,
        modifiedTimeListAndWIPDoneStatus?.anyWipDoneChanges
      );
    }
  };
  saveDraft = (
    value,
    year,
    week,
    saveDraftState,
    userTimeSheetData,
    operationalUserGteEmployeeNo,
    WIPDoneStatus
  ) => {
    saveOrSubmitPatchMethod(year, week, "S", value)
      .then((response) => {
        // let wipDoneChange = this.saveWIPDoneValueChanges(userTimeSheetData);

        // if (wipDoneChange) {
        this.props.setAlerts(operationalUserGteEmployeeNo);

        if (WIPDoneStatus) {
          this.saveWIPDoneValueChanges(userTimeSheetData);
        } else {
          this.refreshTimeSheetData({}, 0, 0, true);
        }
        this.setState({
          saveDraft: false,
          // loading: false,
        });

        if (saveDraftState) {
          this.setState({
            responseMessage: response?.data?.body?.message,
            // showWarningIcon: true,
            response: true,
          });
        }
        // }
      })
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          loading: false,
          error: {
            status:
              message?.status === 401
                ? false
                : message?.status === 404
                ? false
                : true,
            message: message,
          },
        });
      });
  };

  handleSubmitForApproval = (
    userTimeSheetData,
    year,
    week,
    loggedUserGteEmployeeNo,
    operationalUserGteEmployeeNo
  ) => {
    this.setState({
      loading: true,
    });

    let modifiedTimeListAndWIPDoneStatus =
      this.getModifiedTimeListAndWIPDoneStatus(userTimeSheetData);
    let modifiedTime = modifiedTimeListAndWIPDoneStatus?.modifiedTime;
    let value;

    if (modifiedTime?.length > 0) {
      value = {
        identification: "WU/U",
        loggedUserGteEmployeeId: parseInt(loggedUserGteEmployeeNo),
        operationalUserGteEmployeeId: parseInt(operationalUserGteEmployeeNo),
        modifiedTime: modifiedTime,
      };
    } else if (modifiedTime?.length === 0) {
      value = {
        identification: "WU/U",
        loggedUserGteEmployeeId: parseInt(loggedUserGteEmployeeNo),
        operationalUserGteEmployeeId: parseInt(operationalUserGteEmployeeNo),
      };
    }

    // this.saveWIPDoneValueChanges(userTimeSheetData);

    saveOrSubmitPatchMethod(year, week, "U", value)
      .then((response) => {
        this.handleSuccessOrErrorStatus(response?.data, userTimeSheetData);
        // this.refreshTimeSheetData({}, 0, 0, true);
        this.props.setAlerts(operationalUserGteEmployeeNo);
        if (modifiedTimeListAndWIPDoneStatus?.anyWipDoneChanges) {
          this.saveWIPDoneValueChanges(userTimeSheetData);
        } else {
          this.refreshTimeSheetData({}, 0, 0, true);
        }
      })
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          loading: false,
          error: {
            status:
              message?.status === 401
                ? false
                : message?.status === 404
                ? false
                : true,
            message: message,
          },
        });
      });
  };
  getSortedListByDate(list) {
    let sortedList = [];
    list?.map((obj) => {
      sortedList.push({
        date: Object.keys(obj)[0],
        value: Object.values(obj)[0],
      });
    });

    sortedList = sortedList.sort(
      (a, b) =>
        new Date(...a.date.split("/").reverse()) -
        new Date(...b.date.split("/").reverse())
    );

    return sortedList;
  }
  /**
   * Should update -> open model and display data -> remains
   */
  handleSuccessOrErrorStatus = (response, userTimeSheetData) => {
    // if (response?.message === "Successfully Submitted") {
    //   this.saveWIPDoneValueChanges(userTimeSheetData);
    // }
    if (response?.length > 0) {
      let errorModel = this.state.errorModel;
      response?.map((message) => {
        if (message?.maximumHourExceedError?.length > 0) {
          let maximumHourExceedErrorList = this.getSortedListByDate(
            message?.maximumHourExceedError
          );
          errorModel.maximumHourExceedError = {
            error: true,
            errorList: maximumHourExceedErrorList,
          };
        } else if (message?.minimumHourExceedError?.length > 0) {
          let minimumHourExceedErrorList = this.getSortedListByDate(
            message?.minimumHourExceedError
          );
          errorModel.minimumHourExceedError = {
            error: true,
            errorList: minimumHourExceedErrorList,
          };
        } else if (message?.invoiceCommentMissingError?.length > 0) {
          let invoiceCommentMissingErrorList = this.getSortedListByDate(
            message?.invoiceCommentMissingError
          );
          errorModel.invoiceCommentMissingError = {
            error: true,
            errorList: invoiceCommentMissingErrorList,
          };
        }
      });

      errorModel.openErrorModel = true;
      this.setState({
        errorModel: errorModel,
      });
    } else {
      this.setState({
        responseMessage: response?.message,
        response: true,
      });
    }

    // this.refreshTimeSheetData({}, 0, 0, true);
  };

  handleCalender() {
    let calender = !this.state.openCalender;
    this.setState({ openCalender: true });
  }
  updateStateOfRightPanelDivClassNameProperty(rightPanelDivClassNameProperty) {
    this.setState({
      rightPanelDivClassNameProperty: rightPanelDivClassNameProperty,
    });
  }
  handleNonProjectAlertButton() {
    let rightPanelDivClassNameProperty =
      this.state.rightPanelDivClassNameProperty;
    rightPanelDivClassNameProperty.alerts = {
      button: "accordion-button",
      div: "accordion-collapse collapse show",
    };
    rightPanelDivClassNameProperty.addTask = {
      button: "accordion-button collapsed",
      div: "accordion-collapse collapse",
    };
    rightPanelDivClassNameProperty.selectedTask = {
      button: "accordion-button collapsed",
      div: "accordion-collapse collapse",
    };

    if (this.props.clickedAlert) {
      this.updateStateOfRightPanelDivClassNameProperty(
        rightPanelDivClassNameProperty
      );
      this.props.updateStateOfClickedAlert(false);
    }
  }
  unfreezeButton() {
    this.setState({ unfreezeModal: true });
  }
  copyWeekButton() {
    this.setState({ loading: true });

    copyWeekMethod(
      "?gte-employee-no=",
      this.props.operationalUserGteEmployeeNo,
      "&year=",
      this.state.selectedDate.year,
      "&week-no=",
      this.state.selectedDate.week,
      "&logged-user-gte-employee-no=",
      this.state.loggedUserGteEmployeeNo
    )
      .then((response) => {
        this.setState({
          responseMessage: response?.data?.body?.message,
          response: true,
          loading: false,
        });

        this.getUserTimeSheetData(this.state.userTimeSheetData);
      })
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          loading: false,
          error: {
            status:
              message?.status === 401
                ? false
                : message?.status === 404
                ? false
                : true,
            message: message,
          },
        });
      });
  }
  copyUnassignTaskButton() {
    this.setState({ loading: true });

    copyUnAssignTaskMethod(
      "?gte-employee-no=",
      this.props.operationalUserGteEmployeeNo,
      "&year=",
      this.state.selectedDate.year,
      "&week-no=",
      this.state.selectedDate.week,
      "&logged-user-gte-employee-no=",
      this.state.loggedUserGteEmployeeNo
    )
      .then((response) => {
        this.setState({
          responseMessage: response?.data?.message,
          response: true,
          loading: false,
        });

        if (
          response?.data?.message ===
          "Tasks Successfully Copied from Previous Week!"
        ) {
          this.setState({ callUnassignTask: true });
        } else {
          this.setState({ callUnassignTask: false });
        }

        this.getUserTimeSheetData(this.state.userTimeSheetData);
      })
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          loading: false,
          error: {
            status:
              message?.status === 401
                ? false
                : message?.status === 404
                ? false
                : true,
            message: message,
          },
        });
      });
  }
  addTaskResponse = (response) => {
    // console.log("response ", response);
    this.setState({
      responseMessage: response?.data?.Status,
      response: true,
    });
  };
  setResponseMessage = (message) => {
    this.setState({
      responseMessage: message,
    });
    return true;
  };
  setStateOfResponse = (value) => {
    this.setState({
      response: value,
    });
  };
  formatNumberToTwoDecimals(value) {
    const formattedValue = parseFloat(value).toFixed(2);
    return formattedValue.replace(/\.00$/, "").replace(/(\.\d*?)0+$/, "$1");
  }

  updateStateofIsTaskDone(value) {
    this.setState({
      isTaskDone: value,
    });
  }
  render() {
    const { bannerVisible, feedbackVisible } = this.state;
    // console.log("bannerVisible 1: " + bannerVisible);

    return (
      <React.Fragment>
        {bannerVisible ? (
          <Banner
            bannerVisible={(value) => {
              this.setState({ bannerVisible: value });
            }}
          />
        ) : (
          <div>
            {feedbackVisible && (
              <Feedback
                loggedUser={this.props.loggedUser}
                hide={() => {
                  this.setState({
                    toast: false,
                  });
                }}
                feedbackVisible={(value) => {
                  this.setState({
                    feedbackVisible: value,
                  });
                  console.log("value : " + value);
                }}
              ></Feedback>
            )}
          </div>
        )}
        <div className="ch-main-nav">
          <div className="week-nav">
            <button
              // tabIndex={0}
              class="prev-week"
              disabled={
                !this.state.clickInput ||
                parseInt(this.state.selectedDate?.year) <
                  this.state.selectedDate?.maxPastNavigateYear
              }
              onClick={() => {
                this.pastWeek();
              }}
              // data-toggle="tooltip"
              // data-placement="left"
              title="Navigate to previous week"
              // alt="Navigate to Previous week"
            ></button>
            <button
              // tabIndex={0}
              ref={this.calendarRef}
              class="date"
              onClick={() => {
                this.handleCalender();
              }}
              disabled={!this.state.clickInput}
              // data-toggle="tooltip"
              // data-placement="left"
              title="Navigate to Next/Previous Week By Using Calender Popup"
              // alt="Navigate to Nex/Previous Week By Using Calender Popup"
            >
              {" "}
              {this.state.selectedDate.dateOfFirstDayofCurrentWeek} {" to "}
              {this.state.selectedDate.dateOfLastDayofCurrentWeek}
              {this.state.openCalender && (
                <Calender
                  openCalender={this.state.openCalender}
                  getSelectedDate={(date) => {
                    this.setState({ datePicker: date });
                  }}
                  selectedWeekFirstDate={
                    this.state.selectedDate.firstDayofCurrentWeek
                  }
                />
              )}
            </button>
            <button
              class="next-week"
              tabIndex={0}
              disabled={!this.state.clickInput || this.state.disableNextBtn}
              onClick={() => {
                this.nextWeek();
              }}
              // data-toggle="tooltip"
              // data-placement="left"
              title="Navigate to next week"
              // alt="Navigate to next week"
            ></button>
            <div
              class="week-no"
              tabIndex={0}
              title={` ${this.state.selectedDate.year} week ${this.state.selectedDate.week}`}
            >
              <b>
                {this.state.selectedDate.year} {" Week "}
              </b>
              {this.state.selectedDate.week}
            </div>

            <button
              onClick={() => this.unfreezeButton()}
              className="rounded-pill unfreez"
              disabled={
                this.state.freezeDateData?.isUpdated &&
                this.state.freezeDateData?.isFreezed
              }
            >
              Unfreeze My Week
              {/* <a
                tabIndex={0}
                title="Unfreeze My Week"
                target="_blank"
                className="text-light"
                href={`https://virtusa.service-now.com/sp?id=sc_cat_item&sys_id=d5dc327bdb0518505eb551b0cf9619a5&week_start_date=${this.state.selectedDate?.firstDayofWeekForUnfreeze}`}
              >
                Unfreeze My Week
              </a> */}
            </button>
            <section>
              {this.state.unfreezeModal && (
                <UnfreezeModal
                  tabIndex={0}
                  selectedDate={this.state.selectedDate}
                  showUnfreezeModal={(show) =>
                    this.setState({ unfreezeModal: show })
                  }
                  projectList={this.state.projectList}
                  operationalUserGteEmployeeNo={
                    this.state.operationalUserGteEmployeeNo
                  }
                  loggedUserGteEmployeeNo={this.state.loggedUserGteEmployeeNo}
                />
              )}
            </section>
          </div>

          <div className="nav-actions">
            <a
              tabIndex={0}
              className="copy_previous_week"
              href="#"
              onClick={() => this.copyWeekButton()}
              // data-toggle="tooltip"
              // data-placement="left"
              title="Copy Previous Week Billable Task Times to Current Week"
              // alt="Copy Previous Week Billable Task Times to Current Week"
            >
              Copy Previous Week
            </a>
            <button
              className="btn saveDraft"
              disabled={!this.state.clickInput}
              onClick={() => {
                this.handleSaveDraft(
                  true,
                  false,
                  this.state.userTimeSheetData,
                  this.state.selectedDate.year,
                  this.state.selectedDate.week,
                  this.state.loggedUserGteEmployeeNo,
                  this.state.operationalUserGteEmployeeNo
                );
              }}
              data-toggle="tooltip"
              data-placement="left"
              title="Save as Draft"
              alt="Save as Draft"
              tabIndex={0}
            >
              Save Draft
            </button>

            <button
              tabIndex={0}
              className="btn submit"
              disabled={!this.state.clickInput}
              onClick={() => {
                this.handleSubmitForApproval(
                  this.state.userTimeSheetData,
                  this.state.selectedDate.year,
                  this.state.selectedDate.week,
                  this.state.loggedUserGteEmployeeNo,
                  this.state.operationalUserGteEmployeeNo
                );
              }}
              data-toggle="tooltip"
              data-placement="left"
              title="Submit for Approval"
              alt="Submit for Approval"
            >
              Submit for Approval
            </button>
          </div>
        </div>
        <div className="ch-dynamic-container">
          <TimeEntryMainPage
            updateStateofIsTaskDone={(value) => {
              this.updateStateofIsTaskDone(value);
            }}
            operationalUserGteEmployeeNo={
              this.props.operationalUserGteEmployeeNo
            }
            loggedUserGteEmployeeNo={this.props.loggedUserGteEmployeeNo}
            rightPanelDivClassNameProperty={
              this.state.rightPanelDivClassNameProperty
            }
            deleteTaskData={(res, gteProjectId) => {
              this.setState({
                deleteTaskData: res,
                deleteGteProjectId: gteProjectId,
              });
            }}
            freezeDateData={this.state.freezeDateData}
            selectedDate={this.state.selectedDate}
            selectedTaskValue={this.state.selectedTaskValue}
            projectList={this.state.projectList}
            userTimeSheetData={this.state.userTimeSheetData}
            clickInput={this.state.clickInput}
            isDisabled={!this.state.clickInput}
            loading={this.state.loading}
            addTimeEntered={(value, gteProjectId, task, timedate) => {
              this.timSlotValueUpdate(
                value,
                gteProjectId,
                task,
                timedate,
                "timeChange"
              );
            }}
            validateTimeEntered={(value, gteProjectId, task, timedate) => {
              this.timSlotValueUpdate(
                value,
                gteProjectId,
                task,
                timedate,
                "timeValidation"
              );
            }}
            setSelectedTaskPanelValue={(
              timeSheet,
              gteProjectId,
              task,
              timedate
            ) => {
              this.setSelectedTaskPanelValue(
                timeSheet,
                gteProjectId,
                task,
                timedate
              );
            }}
            updateStateOfUserTimeSheetData={(userTimeSheetData) => {
              this.updateStateOfTimeSheetDataList(userTimeSheetData);
            }}
            updateStateOfProjectList={(projectList) => {
              this.setState({ projectList: projectList });
            }}
            updateStateOfRightPanelDivClassNameProperty={(
              rightPanelDivClassNameProperty
            ) => {
              this.updateStateOfRightPanelDivClassNameProperty(
                rightPanelDivClassNameProperty
              );
            }}
            refreshTimeSheetData={(selectedDate, empNo, projId) => {
              this.refreshTimeSheetData(selectedDate, empNo, projId, false);
            }}
            updateSelectedTaskValue={(selectedTaskValue) => {
              this.updateSelectedTaskValue(selectedTaskValue);
            }}
          />

          <TimeEntryRightPanel
            updateSelectedTaskValue={(selectedTaskValue) => {
              this.updateSelectedTaskValue(selectedTaskValue);
            }}
            isCopyUnassigedTaskEnabled={this.state.isCopyUnassigedTaskEnabled}
            freezeDateData={this.state.freezeDateData}
            addTaskResponse={(response) => {
              this.addTaskResponse(response);
            }}
            callUnassignTask={this.state.callUnassignTask}
            copyUnassignTaskButton={() => {
              this.copyUnassignTaskButton();
            }}
            alertData={this.props.alertData}
            selectedDateYear={(date) => {
              this.setState({ datePicker: date });
            }}
            deleteTaskData={this.state.deleteTaskData}
            deleteGteProjectId={this.state.deleteGteProjectId}
            projectList={this.state.projectList}
            refreshTimeSheetData={(selectedDate, empNo, projId) => {
              this.refreshTimeSheetData(selectedDate, empNo, projId, false);
            }}
            clickInput={this.state.clickInput}
            loggedUserDetails={this.props.loggedUserDetails}
            loggedUserGteEmployeeNo={this.state.loggedUserGteEmployeeNo}
            projectIdList={this.state.projectListIds}
            operationalUserGteEmployeeNo={
              this.props.operationalUserGteEmployeeNo
            }
            selectedDate={this.state.selectedDate}
            addTask={this.state.addTask}
            nonProjectTask={this.state.nonProjectTask}
            unassignedTasks={this.state.unassignedTasks}
            userTimeSheetData={this.state.userTimeSheetData}
            selectedTaskValue={this.state.selectedTaskValue}
            rightPanelDivClassNameProperty={
              this.state.rightPanelDivClassNameProperty
            }
            isDisabled={!this.state.clickInput}
            validateTimeEntered_TimeType={(value, selectedTaskValue, entry) =>
              this.validateTimeEntered_TimeType(value, selectedTaskValue, entry)
            }
            addTimeEntered_TimeType={(value, selectedTaskValue, entry) =>
              this.addTimeEntered_TimeType(
                value,
                selectedTaskValue,
                entry,
                false // not check error in time entry
              )
            }
            highlightSelectedInputOfTimeType={(selectedTaskValue, entry) =>
              this.highlightSelectedInputOfTimeType(selectedTaskValue, entry)
            }
            enterInvoiceCommentOfTimeTypeProject={(
              value,
              selectedTaskValue,
              entry
            ) =>
              this.enterInvoiceCommentOfTimeTypeProject(
                value,
                selectedTaskValue,
                entry
              )
            }
            handleSaveDraft={() =>
              this.handleSaveDraft(
                false,
                false,
                this.state.userTimeSheetData,
                this.state.selectedDate.year,
                this.state.selectedDate.week,
                this.state.loggedUserGteEmployeeNo,
                this.state.operationalUserGteEmployeeNo
              )
            }
            updateStateOfRightPanelDivClassNameProperty={(
              rightPanelDivClassNameProperty
            ) => {
              this.updateStateOfRightPanelDivClassNameProperty(
                rightPanelDivClassNameProperty
              );
            }}
          />
        </div>
        <section>
          {this.state.errorModel?.openErrorModel && (
            <ErrorMessageModelOnSubmit
              minimumHourExceedError={
                this.state.errorModel?.minimumHourExceedError
              }
              maximumHourExceedError={
                this.state.errorModel?.maximumHourExceedError
              }
              invoiceCommentMissingError={
                this.state.errorModel?.invoiceCommentMissingError
              }
              hide={() => {
                this.setState({
                  errorModel: {
                    openErrorModel: false,
                    minimumHourExceedError: { error: false, errorList: [] },
                    maximumHourExceedError: { error: false, errorList: [] },
                    invoiceCommentMissingError: { error: false, errorList: [] },
                  },
                });
              }}
            />
          )}
        </section>
        <section>
          {this.state.isTaskDone && (
            <TaskDoneWarningModel
              alt="Warning Message for Task Done selection"
              taskDone={(task) => {
                this.setState({ isTaskDone: task });
              }}
              updateStateofIsTaskDone={(value) => {
                this.updateStateofIsTaskDone(value);
              }}
            />
          )}
        </section>
        <section>
          {this.state.loading && (
            <CheckInLoadingPopup initialLoading={this.state.initialLoading} />
          )}
        </section>
        <section>
          {this.state.response && (
            <ResponseToast
              responseMessage={
                <div style={{ textAlign: "center" }}>
                  {this.state.responseMessage}!
                  {this.state.responseMessage === "Time Save Successful" && (
                    <div>
                      {this.state.showWarningIcon && (
                        <img
                          src={warningIcon}
                          style={{
                            width: "25px",
                            height: "25px",
                            padding: "2px",
                            display: "inline-block",
                          }}
                          alt="Warning Icon"
                        />
                      )}
                      {" Please Submit Time for Project Manager Approval."}
                    </div>
                  )}
                </div>
              }
              hide={() => {
                this.setStateOfResponse(false);
              }}
            />
          )}
        </section>
        <section>
          <div>
            {this.state.error?.status && (
              <ErrorModel
                loggedUserDetails={this.props.loggedUserDetails}
                errorResponse={this.state.error}
                hide={() => {
                  this.setState({
                    error: {
                      status: false,
                      message: "",
                    },
                  });
                }}
              ></ErrorModel>
            )}
          </div>
        </section>
        {/* console.log("bannerVisible 2: "+bannerVisible); */}
      </React.Fragment>
    );
  }
}

export default MainMenu;
