var V_KEY_CROSS_CODE = 118;
var V_KEY_CODE = 86;
var DOT_KEY_CODE = 46;
var ZERO_KEY_CODE = 48;
var ZERO_KEY_CODE_BROWSER = 47;
var NUMBER_NINE_KEY_CODE = 58;
var NUMBER_NINE_KEY_CODE_BROWSER = 57;
var SPACE_KEY_CODE = 32;
var BROWSER_DEF = 0;
var BACKSPACE_KEY_CODE = 8;
var TAB_KEY_CODE = 9;
var MOUSE_RC_KEY_CODE = 3;
var Z_KEY_CODE = 90;
var Y_KEY_CODE = 89;
export const ALT_KEY_CODE = 18;
var INTERVAL_TIME_THIRTY_SEC = 20000;
var INTERVAL_TIME_FIVE_HUNDRED_SEC = 90000;

export const reasonsToUnfreeze = [
  { key: 1, value: "~~None~~" },
  {
    key: 2,
    value: "On Unplanned long leave/Emergency leave/ Medical leave",
  },
  {
    key: 3,
    value: "Tasks not assigned ",
  },
  { key: 4, value: "No Allocation in the Project assigned to" },
  { key: 5, value: "Login issues " },
  { key: 6, value: "Missed to enter time" },
  { key: 7, value: "Any other reason, please describe" },
];

export const blockInvalidChar = ["e", "E"];
// export const blockInvalidChar = e => ['e', 'E'].includes(e.key) && e.preventDefault();

/**
 * Event - keyUp
 * Validation - Delete the third input. '123' changed to '12'
 * @param event
 */
export function inputValidation(actualValue,maxLength) {
 

  /**
   * Validation - remove leters
   */
  
  if (isNaN(actualValue.toString())) {
    if (actualValue === "." && actualValue.length === 1) {
      actualValue = "0.";
    } else if (
      actualValue === "" ||
      actualValue === null ||
      actualValue === ".." ||
      actualValue === "..."
    ) {
      actualValue = "";
    } else {
      actualValue = "";
    }
  } else {
    // actualValue= actualValue.replace(/[^0-9.]/g, "");
    actualValue = numberValidation(actualValue,maxLength);
  }

 
  return actualValue;
}

export function numberValidation(actualValue,maxLength) {


  //allow only numbers and .

    /**
     * Validation - Delete the third input. '123' changed to '12'
     */
    if (
      //value without decimal
      actualValue.toString().length > maxLength &&
      actualValue.toString().indexOf(".") < 0 // return -1 -> if not present "."
    ) {
      actualValue = actualValue.slice(0, maxLength);
    }
    /**Leading 0 remove
     * Ex : 09 -> 9
     */

    if (
      actualValue.toString().length === maxLength &&
      actualValue.toString().indexOf("0") === 0
    ) {
     
      if (actualValue.toString().indexOf(".") < 0) {
        actualValue = actualValue.slice(1, 2);
      }
    }
    /**
     * ex "00"-> 0
     */
    if (actualValue === "00") {
      actualValue = "0";
    }
    /**
     * validation -> .999 -> .99
     */
    if (actualValue.toString().indexOf(".") === 0) {
      var splitNumber = actualValue?.toString().split(".");
      if (splitNumber[1].length > 2) {
        var newDecimalValue = splitNumber[1].slice(0, 2);
        actualValue = splitNumber[0] + "." + newDecimalValue;
      }
    
    }
    /**
     * validation -> 23.223 -> 23.22
     */
    if (actualValue.toString().indexOf(".") > 0) {
      var splitNumber = actualValue?.toString().split(".");
      if(splitNumber[0].length>maxLength){
        splitNumber[0] =splitNumber[0].slice(0, maxLength);
        /**
         * avoid adding numbers in front ex: 23.223 -> 123.224 [not allowed]
         */
        if (splitNumber[1].length > 0 ) {
          var newDecimalValue = splitNumber[1].slice(0, 2);
          actualValue =splitNumber[0]+ "." + newDecimalValue;
        }
      }

      if (splitNumber[1].length > 2) {
        /**
         *  ex: 23.223 -> 23.22 
         */
        var newDecimalValue = splitNumber[1].slice(0, 2);
        actualValue =splitNumber[0]+ "." + newDecimalValue;
      }
      
    }

    /**
   * if the end decimal point is 0, 
   *  rounded up. (Ex: 9.10 à 9.1)
   */
    if (actualValue.toString().indexOf(".") > 0 && (actualValue.length===(maxLength+2) || actualValue.length===(maxLength+3)) ) {
      var splitNumber = actualValue?.toString().split(".");   
      let myFunc = num => Number(num);     
      var intArr = Array.from(String(splitNumber[1]), myFunc);  
      if(intArr.length>1 && intArr[intArr.length-1] === 0){
     
       actualValue = splitNumber[0] +"."+splitNumber[1].toString().split(0,1)
      
      } 
 
    }
  
  return actualValue;
}
export function inputValidationOnBlur(actualValue){

  if (actualValue.toString().indexOf(".") > 0 ) {
    var splitNumber = actualValue.toString().split(".");   
    if(splitNumber[1].length === 0){
      actualValue = splitNumber[0];
    }else{
      if(splitNumber[1].length ===1 && Number( splitNumber[1])  === 0){
       
        actualValue = splitNumber[0]
      }
    }
    return Number(actualValue);
  }

  return actualValue;
}
// export function inputValidationForRemainingHours(actualValue, maxLength) {
 

//   /**
//    * Validation - remove leters
//    */

//   if (isNaN(actualValue.toString())) {
//     if (actualValue === "." && actualValue.length === 1) {
//       actualValue = "0.";
//     } else if (
//       actualValue === "" ||
//       actualValue === null ||
//       actualValue === ".." ||
//       actualValue === "..."
//     ) {
//       actualValue = "";
//     } else {
//       actualValue = "";
//     }
//   } else {
//     actualValue= actualValue.replace(/[^0-9.]/g, "");

//   /**
//    * Validation - Delete the third input. '123' changed to '12'
//    */
//   if (
//     //value without decimal
//     actualValue.toString().length > maxLength &&
//     actualValue.toString().indexOf(".") < 0 // return -1 -> if not present "."
//   ) {
//     actualValue = actualValue.slice(0, maxLength);
//   }
//   /**Leading 0 remove
//    * Ex : 09 -> 9
//    */
//   if (
//     actualValue.toString().length === 2 &&
//     actualValue.toString().indexOf("0") === 0
//   ) {
   
//     actualValue = actualValue.slice(1, 2);
  
//   }
//   /**
//    * ex "00"-> 0
//    */
//   if (actualValue === "00") {
//     actualValue = "0";
//   }
//   /**
//    * validation -> .999 -> .99
//    */
//   if (actualValue.toString().indexOf(".") === 0) {
//     var splitNumber = actualValue?.split(".");
//     if (splitNumber[1].length > 2) {
//       var newDecimalValue = splitNumber[1].slice(0, 2);
//       actualValue = splitNumber[0] + "." + newDecimalValue;
//     }
//   }
//   /**
//    * validation -> 22.223 -> 22.22
//    */
 
//   if (actualValue.toString().indexOf(".") > 0) {
//     var splitNumber = actualValue?.split(".");
//     if(splitNumber[0].length>maxLength){
//       splitNumber[0] =splitNumber[0].slice(0, maxLength);
//       if (splitNumber[1].length > 0 ) {
//         var newDecimalValue = splitNumber[1].slice(0, 2);
//         actualValue =splitNumber[0]+ "." + newDecimalValue;
//       }
//     }
//     if (splitNumber[1].length > 2) {
//       var newDecimalValue = splitNumber[1].slice(0, 2);
//       actualValue =splitNumber[0]+ "." + newDecimalValue;
//     }
    
//   }
//   /**
//    * if the end decimal point is 0, 
//    *  rounded up. (Ex: 9.10 à 9.1)
//    */
  
//   if (actualValue.toString().indexOf(".") > 0) {
//     var splitNumber = actualValue?.split(".");   
//     let myFunc = num => Number(num);     
//     var intArr = Array.from(String(splitNumber[1]), myFunc);  
//     if(intArr.length>1 && intArr[intArr.length-1] === 0){
//      actualValue = splitNumber[0] +"."+splitNumber[1].split(0,1)
//     }

  
//   }

// }


//   return actualValue;
// }

function preventPasteAction(event) {
  if (event.Event.ctrlKey == true || event.Event.which == ALT_KEY_CODE) {
    event.cancel();
  }
}
