import React, { Component, Fragment } from "react";
import CheckInLogo from "../logo/checkin.png";
import CheckInLogoNew from "../logo/gte_logo.png";
import Dropdown from "react-bootstrap/Dropdown";
import FormControl from "react-bootstrap/FormControl";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import Badge from "react-bootstrap/Badge";
import ResponseToast from "./menuComponents/commonComponents/responseToast";
import { getApplicationGuideInfor } from "../utils/services";
import { getApplicationGuideInforLink } from "../utils/utils";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedUserGteEmployeeNo: 0,
      delegatedUserGteEmployeeNo: 0,
      delegatedUsers: [],
      options: [],
      searchValue: "",
      selectedOption: null,
      response: false,
      responseMessage: "",
      applicationGuide: {
        status: false,
        link: "",
      },
    };
  }

  componentDidMount() {
    this.setSelectedDelegateUser();
    this.setApplicationGuideInfoLink();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.delegatedUserGteEmployeeNo !==
      this.props.operationalUserGteEmployeeNo
    ) {
      this.setSelectedDelegateUser();
      if (this.state.selectedOption !== null) {
        this.setState({
          response: true,
          responseMessage:
            "User switched to " + this.state.selectedOption.delegatedUserName,
        });
      }
    }
    if (this.props.selectedDate !== prevProps.selectedDate) {
      this.setSearchValueEmpty();
    }
  }

  setApplicationGuideInfoLink() {
    const requestId = `REQUEST-${Date.now()}`;
    this.lastRequestId = requestId;

    setTimeout(() => {
      if (this.lastRequestId !== requestId) {
        return;
      } else {
        getApplicationGuideInforLink().then((res) => {
          this.setState({
            applicationGuide: {
              status: true,
              link: res?.data[0]?.configValue,
            },
          });
        });
      }
    }, 1000);
  }
  setSelectedDelegateUser() {
    this.setState({
      delegatedUsers: this.props.delegatedUsers,
      loggedUserGteEmployeeNo: this.props.loggedUserGteEmployeeNo,
      delegatedUserGteEmployeeNo: this.props.operationalUserGteEmployeeNo,
    });
  }

  handleSearchChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  handleOptionSelect = (option) => {
    this.setState({ selectedOption: option, searchValue: "" });

    this.props.handleDelegatedUser(
      parseInt(option.delegatedUserGteEmployeeNo),
      this.state.loggedUserGteEmployeeNo
    );
  };
  setStateOfResponse = (value) => {
    this.setState({
      response: value,
    });
  };
  setSearchValueEmpty() {
    this.setState({ searchValue: "" });
  }

  renderLogInAndLogoutOption = () => {
    const { delegatedUsers, searchValue, selectedOption } = this.state;

    const filteredOptions = delegatedUsers?.filter((option) =>
      /*option.delegatedUserName.toLowerCase().includes(searchValue.toLowerCase())*/
      option.delegatedUserName
        .toLowerCase()
        .startsWith(searchValue.toLowerCase())
    );

    if (this.props.loggedUserDetails === null) {
      return (
        <Fragment>
          <li className="row">
            {/* <button
            className="btn btn-outline-success"
            type="submit"
            onClick={this.props.logIn}
          >
            Log in
          </button> */}
          </li>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="row">
            <div
              className="col-2 notifications"
              // data-toggle="tooltip"
              // data-placement="left"
              title={`${this.props.alertData?.alertSummary} notifications`}
              alt={`${this.props.alertData?.alertSummary} notifications`}
            >
              <button
                title={`alerts : ${this.props.alertData?.alertSummary} notifications`}
                onClick={() => this.props.updateStateOfClickedAlert(true)}
              >
                <Badge
                  bg="secondary"

                  // data-toggle="tooltip"
                  // data-placement="left"
                  // title={this.props.alertData?.alertSummary}
                  // alt={this.props.alertData?.alertSummary}
                >
                  {this.props.alertData?.alertSummary}
                </Badge>
              </button>
            </div>
            <div className="col-8 float-end user-dropdown">
              <Dropdown>
                <Dropdown.Toggle
                  variant="light"
                  id="dropdown-basic"
                  title={
                    selectedOption
                      ? selectedOption.delegatedUserName
                      : this.props.loggedUserDetails.displayName
                  }
                >
                  {selectedOption
                    ? selectedOption.delegatedUserName
                    : this.props.loggedUserDetails.displayName}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{ maxHeight: "250px", overflowY: "scroll" }}
                >
                  <DropdownItem
                    key={this.state.loggedUserGteEmployeeNo}
                    title={this.props.loggedUserDetails?.displayName}
                    value={this.state.loggedUserGteEmployeeNo}
                    onClick={() =>
                      this.handleOptionSelect({
                        delegatedUserGteEmployeeNo:
                          this.props.loggedUserGteEmployeeNo,
                        delegatedUserName:
                          this.props.loggedUserDetails?.displayName,
                      })
                    }
                  >
                    {this.props.loggedUserDetails?.displayName}
                  </DropdownItem>
                  <DropdownItem
                    title="Home"
                    onClick={() => {
                      this.props.handleSave();
                      window.location.href =
                        "https://ecgy.fa.ap2.oraclecloud.com:443/fscmUI/faces/FuseWelcome";
                    }}
                  >
                    Home
                  </DropdownItem>
                  <FormControl
                    className="user-list"
                    type="text"
                    placeholder="Search..."
                    value={searchValue}
                    onChange={this.handleSearchChange}
                  />
                  {filteredOptions?.map((option) => (
                    <Dropdown.Item
                      key={option.delegatedUserGteEmployeeNo}
                      title={option.delegatedUserName}
                      onClick={() => this.handleOptionSelect(option)}
                    >
                      {option.delegatedUserName}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-2 application_guide">
              {/* <a href={this.state.applicationGuide.link} disabled={!this.state.applicationGuide?.status}  title="Checkin Application Guide"> */}
              <button
                disabled={!this.state.applicationGuide?.status}
                onClick={(e) => {
                  this.props.handleSave();
                  e.preventDefault();
                  window.open(this.state.applicationGuide.link, "_blank");
                }}
                // data-toggle="tooltip"
                // data-placement="left"
                tabIndex={0}
                title="Checkin Application Guide"
                alt="Checkin Application Guide"
              >
                <Badge bg="secondary"></Badge>
              </button>
              {/* </a> */}
            </div>
          </div>
        </Fragment>
      );
    }
  };

  render() {
    return (
      <div className="ch-header">
        <div
          tabIndex={0}
          className="app-logo"
          // data-toggle="tooltip"
          // data-placement="left"
          title="CheckIn"
          alt="CheckIn Logo"
        >
          <img src={CheckInLogoNew} alt="CheckIn" />
        </div>
        {/* <div className="blank"></div> */}
        <div className="logged-user-info">
          <ul className="logged-users">{this.renderLogInAndLogoutOption()}</ul>
        </div>
        <section>
          {this.state.response && (
            <ResponseToast
              responseMessage={this.state.responseMessage}
              hide={() => {
                this.setStateOfResponse(false);
              }}
            />
          )}
        </section>
      </div>
    );
  }
}

export default NavBar;
