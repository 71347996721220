import axios from "axios";
import {
  getRequestMethod,
  getTeamMemberDataMethod,
  getTimeSheetList,
  taskDonePatchMethod,
  getAlertsMethod,
  getApplicationGuideInforLink,
} from "./utils";
import envConfig from "../environment-property/property.json";
import { getTeamMemberAndTaskList_Dummy } from "./data";

/*
 *  UserTimeSheet data : update response with parameter of year, week which required for validation befor fetch data
 */

export const fetchData = (
  gteProjectId,
  year,
  week,
  operationalUserGteEmployeeNo,
  loggedUserGteEmployeeNo
) => {
  const result = fetchTimeSheetList(
    gteProjectId,
    year,
    week,
    operationalUserGteEmployeeNo,
    loggedUserGteEmployeeNo
  );

  return result;
 
};

const fetchTimeSheetList = (
  gteProjectId,
  year,
  week,
  operationalUserGteEmployeeNo,
  loggedUserGteEmployeeNo
) => {
  return new Promise((resolve) => {
    resolve(
      getTimeSheetList(
        "?gte-employee-no=",
        operationalUserGteEmployeeNo,
        "&year=",
        year,
        "&week-no=",
        week,
        "&gte-project-id=",
        gteProjectId,
        "&logged-user-gte-employee-no=",
        loggedUserGteEmployeeNo
      ).then((res) => {
        return {
          userTimeSheetData: res?.data,
          year: year,
          week: week,
          operationalUserGteEmployeeNo: operationalUserGteEmployeeNo,
        };
      })
    );
  });
};

export const updateTaskDone = (taskAssignmentId, value) => {
  return new Promise((resolve) => {
    resolve(
      taskDonePatchMethod(taskAssignmentId, value).then((res) => {
      //  console.log(res?.data)
        return {
          "taskAssignmentId":taskAssignmentId,
          "response":res?.data
        };
      })
    );
  });
};

export const getAlerts = (operationalUserGteEmployeeNo,loggedUserGteEmployeeNo) => {
  return new Promise((resolve) => {
    resolve(
      getAlertsMethod("?global-employee-id=", operationalUserGteEmployeeNo,"&logged-user-gte-employee-no=",loggedUserGteEmployeeNo).then((res) => {
        return res?.data;
      })
    );
  });
};


