import React, { Component } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Alerts from "./timeEntryRightPanelComponents/alerts";
import AddTask from "./timeEntryRightPanelComponents/addTask";
import SelectedTask from "./timeEntryRightPanelComponents/selectedTask";

class TimeEntryRightPanel extends Component {
  state = {};
  handlePanelCollapse(rightPanelDivClassNameProperty, type) {
    let selectedPanelState;
    if (type === "selectedTask") {
      let selectedPanelState = rightPanelDivClassNameProperty.selectedTask;
      if (selectedPanelState.button === "accordion-button collapsed") {
        rightPanelDivClassNameProperty.alerts = {
          button: "accordion-button collapsed",
          div: "accordion-collapse collapse",
        };
        rightPanelDivClassNameProperty.addTask = {
          button: "accordion-button collapsed",
          div: "accordion-collapse collapse",
        };
        rightPanelDivClassNameProperty.selectedTask = {
          button: "accordion-button",
          div: "accordion-collapse collapse show",
        };
      } else if (selectedPanelState.button === "accordion-button") {
        rightPanelDivClassNameProperty.alerts = {
          button: "accordion-button collapsed",
          div: "accordion-collapse collapse",
        };
        rightPanelDivClassNameProperty.addTask = {
          button: "accordion-button collapsed",
          div: "accordion-collapse collapse",
        };
        rightPanelDivClassNameProperty.selectedTask = {
          button: "accordion-button collapsed",
          div: "accordion-collapse collapse",
        };
      }
    }
    if (type === "addTask") {
      selectedPanelState = rightPanelDivClassNameProperty.addTask;
      if (selectedPanelState.button === "accordion-button collapsed") {
        rightPanelDivClassNameProperty.alerts = {
          button: "accordion-button collapsed",
          div: "accordion-collapse collapse",
        };
        rightPanelDivClassNameProperty.addTask = {
          button: "accordion-button",
          div: "accordion-collapse collapse show",
        };
        rightPanelDivClassNameProperty.selectedTask = {
          button: "accordion-button collapsed",
          div: "accordion-collapse collapse",
        };
      } else if (selectedPanelState.button === "accordion-button") {
        rightPanelDivClassNameProperty.alerts = {
          button: "accordion-button collapsed",
          div: "accordion-collapse collapse",
        };
        rightPanelDivClassNameProperty.addTask = {
          button: "accordion-button collapsed",
          div: "accordion-collapse collapse",
        };
        rightPanelDivClassNameProperty.selectedTask = {
          button: "accordion-button collapsed",
          div: "accordion-collapse collapse",
        };
      }
    }
    if (type === "alerts") {
      selectedPanelState = rightPanelDivClassNameProperty.alerts;
      if (selectedPanelState.button === "accordion-button collapsed") {
        rightPanelDivClassNameProperty.alerts = {
          button: "accordion-button",
          div: "accordion-collapse collapse show",
        };
        rightPanelDivClassNameProperty.addTask = {
          button: "accordion-button collapsed",
          div: "accordion-collapse collapse",
        };
        rightPanelDivClassNameProperty.selectedTask = {
          button: "accordion-button collapsed",
          div: "accordion-collapse collapse",
        };
      } else if (selectedPanelState.button === "accordion-button") {
        rightPanelDivClassNameProperty.alerts = {
          button: "accordion-button collapsed",
          div: "accordion-collapse collapse",
        };
        rightPanelDivClassNameProperty.addTask = {
          button: "accordion-button collapsed",
          div: "accordion-collapse collapse",
        };
        rightPanelDivClassNameProperty.selectedTask = {
          button: "accordion-button collapsed",
          div: "accordion-collapse collapse",
        };
      }
    }

    this.props.updateStateOfRightPanelDivClassNameProperty(
      rightPanelDivClassNameProperty
    );
  }

  render() {
    return (
      <section className="ch-panel-section">
        <div className="accordion accordion-flush" id="accordionFlushExample">
          <div className="accordion-item">
            <span className="accordion-header alert-header">
              <button
                className={
                  this.props.rightPanelDivClassNameProperty?.alerts?.button
                }
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
                onClick={() =>
                  this.handlePanelCollapse(
                    this.props.rightPanelDivClassNameProperty,
                    "alerts"
                  )
                }
                data-toggle="tooltip"
                data-placement="left"
                title="Alerts"
                alt="Alerts"
              >
                <span className="ch-accordian-header-icon ch-alert"></span>
                Alerts
              </button>
            </span>
            <div
              id="flush-collapseOne"
              className={this.props.rightPanelDivClassNameProperty?.alerts?.div}
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <Alerts
                  alertData={this.props.alertData}
                  selectedDateYear={(date) => {
                    this.props.selectedDateYear(date);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <span className="accordion-header add-task-header">
              <button
                className={
                  this.props.rightPanelDivClassNameProperty?.addTask?.button
                }
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
                onClick={() =>
                  this.handlePanelCollapse(
                    this.props.rightPanelDivClassNameProperty,
                    "addTask"
                  )
                }
                data-toggle="tooltip"
                data-placement="left"
                title="Add Task"
                alt="Add Task"
              >
                <span className="ch-accordian-header-icon add-task"></span>
                Add Task
              </button>
            </span>
            <div
              id="flush-collapseTwo"
              className={
                this.props.rightPanelDivClassNameProperty?.addTask?.div
              }
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <AddTask
                  isCopyUnassigedTaskEnabled={
                    this.props.isCopyUnassigedTaskEnabled
                  }
                  addTaskResponse={(response) => {
                    this.props.addTaskResponse(response);
                  }}
                  callUnassignTask={this.props.callUnassignTask}
                  copyUnassignTaskButton={() => {
                    this.props.copyUnassignTaskButton();
                  }}
                  deleteTaskData={this.props.deleteTaskData}
                  deleteGteProjectId={this.props.deleteGteProjectId}
                  refreshTimeSheetData={(selectedDate, empNo, projId) => {
                    this.props.refreshTimeSheetData(
                      selectedDate,
                      empNo,
                      projId
                    );
                  }}
                  clickInput={this.props.clickInput}
                  projectList={this.props.projectList}
                  loggedUserDetails={this.props.loggedUserDetails}
                  loggedUserGteEmployeeNo={this.props.loggedUserGteEmployeeNo}
                  projectIdList={this.props.projectIdList}
                  operationalUserGteEmployeeNo={
                    this.props.operationalUserGteEmployeeNo
                  }
                  selectedDate={this.props.selectedDate}
                  addTask={this.props.addTask}
                  nonProjectTask={this.props.nonProjectTask}
                  unassignedTasks={this.props.unassignedTasks}
                  handleSaveDraft={() => {
                    this.props.handleSaveDraft();
                  }}
                />
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <span className="accordion-header sel-task-header">
              <button
                className={
                  this.props.rightPanelDivClassNameProperty?.selectedTask
                    ?.button
                }
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="true"
                aria-controls="flush-collapseThree"
                onClick={() =>
                  this.handlePanelCollapse(
                    this.props.rightPanelDivClassNameProperty,
                    "selectedTask"
                  )
                }
                data-toggle="tooltip"
                data-placement="left"
                title="Selected Task"
                alt="Selected Task"
              >
                <span className="ch-accordian-header-icon selected-task"></span>
                Selected Task
              </button>
            </span>
            <div
              id="flush-collapseThree"
              className={
                this.props.rightPanelDivClassNameProperty?.selectedTask?.div
              }
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <SelectedTask
                 updateSelectedTaskValue={(selectedTaskValue) => {
                  this.props.updateSelectedTaskValue(selectedTaskValue);
                }}
                  freezeDateData={this.props.freezeDateData}
                  isDisabled={!this.state.clickInput}
                  clickInput={this.state.clickInput}
                  userTimeSheetData={this.props.userTimeSheetData}
                  selectedTaskValue={this.props.selectedTaskValue}
                  validateTimeEntered_TimeType={(
                    value,
                    selectedTaskValue,
                    entry
                  ) =>
                    this.props.validateTimeEntered_TimeType(
                      value,
                      selectedTaskValue,
                      entry
                    )
                  }
                  addTimeEntered_TimeType={(value, selectedTaskValue, entry) =>
                    this.props.addTimeEntered_TimeType(
                      value,
                      selectedTaskValue,
                      entry
                    )
                  }
                  highlightSelectedInputOfTimeType={(
                    errorState,
                    selectedTaskValue,
                    entry
                  ) =>
                    this.props.highlightSelectedInputOfTimeType(
                      errorState,
                      selectedTaskValue,
                      entry
                    )
                  }
                  enterInvoiceCommentOfTimeTypeProject={(
                    value,
                    selectedTaskValue,
                    entry
                  ) =>
                    this.props.enterInvoiceCommentOfTimeTypeProject(
                      value,
                      selectedTaskValue,
                      entry
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default TimeEntryRightPanel;
