import React, { Component } from "react";
import Select from "react-select";
import Dropdown from "react-bootstrap/Dropdown";
import FormControl from "react-bootstrap/FormControl";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import {
  patchMethod,
  getErrorMessage,
  getUnassignedTasks,
  getGlobalTasks,
} from "../../../utils/utils";

class AddTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {
        status: false,
        message: "",
      },
      selectProjectType: "nonProject",
      temp: { key: 0 },
      globalTasks: [],
      selectedProjectTasks: this.props.unassignedTasks[0],
      searchValue: "",
      searchTaskValue: "",
      unassignedProjects: [],
      selectedProject: [],
      selectedTask: [],
      options: [],
      selectedOption: [],
      selectedOptionNonproject: {},
      taskName: "",
      selectedUnassignedTask: [],
      disable: false,
      nonProjectTask: [],
      disableNonProjectTaskBtn: true,
      sameUser: false,
      disableNonProject: false,
      isCopyUnassigedTaskEnabled:false,
    };
    this.lastRequestId = null;
  }

  componentDidMount() {
    this.updateRequiredData();
    this.getGlobalTasks();
    this.setState({
      isDissable: !this.props.clickInput,
      isCopyUnassigedTaskEnabled:this.props.isCopyUnassigedTaskEnabled
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isCopyUnassigedTaskEnabled !== this.props.isCopyUnassigedTaskEnabled) {
      this.setState({
        isCopyUnassigedTaskEnabled:this.props.isCopyUnassigedTaskEnabled
      })
    }
    if (prevProps.projectIdList !== this.props.projectIdList) {
      this.handleUnassignedTasks();
    }
    if (prevProps.unassignedTasks !== this.props.unassignedTasks) {
      this.updateRequiredData();
    }
    if (prevProps.nonProjectTask !== this.props.nonProjectTask) {
      this.updateRequiredData();
    }
    if (
      prevProps.callUnassignTask !== this.props.callUnassignTask &&
      this.props.callUnassignTask === true
    ) {
      this.handleUnassignedTasks();
    }
    if (
      prevState.selectProjectType !== this.state.selectProjectType &&
      this.state.selectProjectType.toLowerCase() === "unassigned"
    ) {
      this.handleUnassignedTasks();
    } else {
    }
    if (
      prevState.selectedOption !== this.state.selectedOption &&
      this.state.selectedOption !== undefined
    ) {
      const disable = this.state.selectedOption.tasks.length === 0;
      this.setState({ disable });
      if (this.state.selectedOption.tasks.length !== 0) {
        this.setState({
          selectedUnassignedTask: this.state.selectedOption.tasks[0],
        });
      }
    }
    if (prevState.taskName !== this.state.taskName) {
      const disable = this.state.taskName.length === 0;
      this.setState({ disableNonProjectTaskBtn: disable });
    }
    if (
      this.props.loggedUserGteEmployeeNo ===
        this.props.operationalUserGteEmployeeNo &&
      this.props.loggedUserGteEmployeeNo !== 0 &&
      this.state.sameUser === false
    ) {
      this.setState({ sameUser: true, disableNonProject: false, taskName: "" });
      this.getGlobalTasks();
    }

    if (
      this.props.operationalUserGteEmployeeNo !==
      prevProps.operationalUserGteEmployeeNo
    ) {
      if (prevProps.projectIdList !== this.props.projectIdList) {
      }
      const requestId = `REQUEST-${Date.now()}`;
      this.lastRequestId = requestId;
      /**
       * set time delay to call handleUnassignedTasks()
       * -> when change delegate user
       * -> required time delay to update projectId list
       */
      setTimeout(() => {
        if (this.lastRequestId !== requestId) {
          return;
        } else {
          this.setState({
            sameUser: false,
            disableNonProject: true,
            nonProjectTask: [],
            selectedOptionNonproject: {},
            taskName: "",
          });
          this.handleUnassignedTasks();
        }
      }, 1000);
    }

    if (
      this.props.selectedDate.dateOfFirstDayofCurrentWeek !==
      prevProps.selectedDate.dateOfFirstDayofCurrentWeek
    ) {
      this.setState({ taskName: "" });
      // this.handleUnassignedTasks();
      this.getGlobalTasks();
      this.setUnassignedSearchValueEmpty();
    }

    if (
      prevProps.deleteTaskData !== this.props.deleteTaskData &&
      this.props.deleteGteProjectId !== -1
    ) {
      this.handleUnassignedTasks();
    }
  }

  updateRequiredData = () => {
    this.setState({
      selectedOption: this.props.unassignedTasks[0],
      selectedOptionNonproject: this.state.nonProjectTask[0],
      unassignedProjects: this.props.unassignedTasks,
    });
  };
  loadDropDownData() {
    this.setState({
      selectedProject: {
        value: this.props.unassignedTasks[0].gteProjectId,
        label: this.props.unassignedTasks[0].projectName,
      },
    });
  }

  nonProjectBtn = () => {
    this.setState({
      selectProjectType: "nonProject",
    });
  };

  unassignedBtn = () => {
    this.setState({
      selectProjectType: "unAssigned",
    });
  };

  checkUnassignedTaskIsEmpty() {
    if (
      this.state.selectedOption !== undefined &&
      this.state.selectedOption.tasks.length !== 0
    ) {
      return this.state.selectedUnassignedTask?.taskName;
    } else {
      return "Select an option";
    }
  }

  handleSelectedChange = (event) => {
    const selectedValue = parseInt(event.target.value);
    const selectedOption = this.state.nonProjectTask.find(
      (option) => option.standardCodeId === selectedValue
    );
    this.setState({ selectedOptionNonproject: selectedOption });
  };

  handleSearchChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };
  handleSearchTaskChange = (e) => {
    this.setState({ searchTaskValue: e.target.value });
  };
  handleSearchNonProjectChange = (selectedOption) => {
    this.setState({ selectedOptionNonproject: selectedOption });
  };

  handleOptionSelect = (option) => {
    if (option.gteProjectId !== this.state.selectedOption.gteProjectId) {
      this.setState({
        selectedUnassignedTask: [],
        selectedOption: option,
        searchValue: "",
      });
    }
  };
  handleOptionSelectNonproject = (option) => {
    this.setState({ selectedOptionNonproject: option });
  };
  handleOptionSelectUnassignedTask = (option) => {
    this.setState({ selectedUnassignedTask: option });
  };

  handleNonProjectSave = () => {
    this.props.handleSaveDraft();
    this.setState({ taskName: "" });
    const value = {
      gteProjectId: -1,
      gteEmployeeNo: this.props.operationalUserGteEmployeeNo,
      loggedUserGteEmployeeNo: parseInt(this.props.loggedUserGteEmployeeNo),
      year: this.props.selectedDate.year,
      weekNo: this.props.selectedDate.week,
      source: "CheckIn Web",
      taskSource: "SELF",
      task: {
        taskName: this.state.taskName,
        standardCode: this.state.selectedOptionNonproject.standardCodeId,
      },
    };
    patchMethod(value)
      .then((response) => {
        this.props.addTaskResponse(response);
        this.props.refreshTimeSheetData(
          this.props.selectedDate,
          this.props.operationalUserGteEmployeeNo,
          -1
        );
      })
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          loading: false,
          error: {
            status:
              message?.status === 401
                ? false
                : message?.status === 404
                ? false
                : true,
            message: message,
          },
        });
      });
  };
  handleUnassignedTaskSave = () => {
    this.setState({ disable: true });
    this.props.handleSaveDraft();
    const projectId = parseInt(this.state.selectedOption.gteProjectId);

    const value = {
      gteProjectId: projectId,
      gteEmployeeNo: parseInt(this.props.operationalUserGteEmployeeNo),
      loggedUserGteEmployeeNo: parseInt(this.props.loggedUserGteEmployeeNo),
      year: this.props.selectedDate.year,
      weekNo: this.props.selectedDate.week,
      source: "CheckIn Web",
      taskSource: "SELF",
      task: {
        taskId: parseInt(this.state.selectedUnassignedTask.taskId),
      },
    };
    patchMethod(value)
      .then((response) => {
        this.props.addTaskResponse(response);
        this.props.refreshTimeSheetData(
          this.props.selectedDate,
          this.props.operationalUserGteEmployeeNo,
          projectId
        );
        this.handleUnassignedTasks();
        this.setState({ disable: false });
      })
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          loading: false,
          error: {
            status:
              message?.status === 401
                ? false
                : message?.status === 404
                ? false
                : true,
            message: message,
          },
        });
      });
  };

  getGlobalTasks() {
    let user = this.props.loggedUserDetails;
    if (user !== null) {
      this.setState({ nonProjectTask: null });

      getGlobalTasks("?is-global=", "Y")
        .then((response) => {
          this.setState({
            nonProjectTask: response?.data?.sort((a, b) =>
              a.standardCodeName > b.standardCodeName ? 1 : -1
            ),
            selectedOptionNonproject: response.data[0],
          });
        })
        .catch((error) => {
          let message = getErrorMessage(error);
          this.setState({
            loading: false,
            error: {
              status:
                message?.status === 401
                  ? false
                  : message?.status === 404
                  ? false
                  : true,
              message: message,
            },
          });
        });
    }
  }

  setUnassignTaskEmpty() {
    this.setState({ unassignedProjects: [], searchTaskValue: "" });
  }
  setUnassignedSearchValueEmpty() {
    this.setState({ searchValue: "", searchTaskValue: "" });
  }

  handleUnassignedTasks() {
    this.setUnassignTaskEmpty();

    getUnassignedTasks(
      "?gte-employee-no=",
      this.props.operationalUserGteEmployeeNo,
      "&year=",
      this.props.selectedDate.year,
      "&week-no=",
      this.props.selectedDate.week,
      "&gte-project-ids=",
      this.props.projectIdList,
      "&logged-user-gte-employee-no=",
      this.props.loggedUserGteEmployeeNo
    )
      .then((response) => {
        this.setState({
          unassignedProjects: response?.data.unassignedTasks?.sort((a, b) =>
            a.projectName > b.projectName ? 1 : -1
          ),
          selectedOption: response?.data.unassignedTasks[0],
        });
      })
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          loading: false,
          error: {
            status:
              message?.status === 401
                ? false
                : message?.status === 404
                ? false
                : true,
            message: message,
          },
        });
      });
  }

  render() {
    const { selectedProject, unassignedProjects } = this.state;

    const {
      options,
      searchValue,
      selectedOption,
      searchTaskValue,
      selectedOptionNonproject,
      selectedUnassignedTask,
      disable,
      disableNonProjectTaskBtn,
    } = this.state;

    const filteredOptions = this.state.unassignedProjects.filter((option) =>
      /*option.projectName.toLowerCase().includes(searchValue.toLowerCase())*/
      option.projectName.toLowerCase().startsWith(searchValue.toLowerCase())
    );

    const filteredTasks = selectedOption?.tasks
      ?.sort((a, b) => (a.taskName > b.taskName ? 1 : -1))
      .filter((task) =>
        task.taskName.toLowerCase().startsWith(searchTaskValue.toLowerCase())
      );

    let { disableNonProject } = this.state;

    return (
      <div>
        <div>
          <div className="btn-group project-type-btn">
            <button
              tabIndex={0}
              type="button"
              className={`btn ${
                this.state.selectProjectType === "nonProject" ? "active" : ""
              }`}
              ref={this.buttonRef}
              onClick={this.nonProjectBtn}
            >
              Non Project
            </button>
            <button
              tabIndex={0}
              type="button"
              className={`btn ${
                this.state.selectProjectType === "unAssigned" ? "active" : ""
              }`}
              onClick={this.unassignedBtn}
            >
              Unassigned
            </button>
          </div>

       
          {this.state.selectProjectType === "nonProject" && (
            <div className="ch-non-project">
              <div className="">
                <text>Non-Project Task Type :</text>
                <div maxLength="100px">
                  <Dropdown>
                    <Dropdown.Toggle
                      tabIndex={0}
                      variant="light"
                      id="dropdown-basic"
                      disabled={disableNonProject}
                      data-toggle="tooltip"
                      data-placement="left"
                      title={selectedOptionNonproject?.hasOwnProperty("standardCodeName")
                      ? selectedOptionNonproject?.standardCodeName
                      : "Not Applicable"}
                      alt={selectedOptionNonproject?.hasOwnProperty("standardCodeName")
                      ? selectedOptionNonproject?.standardCodeName
                      : "Not Applicable"}
                    >
                      {selectedOptionNonproject?.hasOwnProperty("standardCodeName")
                        ? selectedOptionNonproject?.standardCodeName
                        : "Not Applicable"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{ maxHeight: "170px", overflow: "auto" }}
                    >
                      {this.state.nonProjectTask?.map((option) => (
                        <Dropdown.Item
                          key={option.standardCodeId}
                          value={option.standardCodeId}
                          onClick={() =>
                            this.handleSearchNonProjectChange(option)
                          }
                          data-toggle="tooltip"
                          data-placement="left"
                          title={option.standardCodeName}
                          alt={option.standardCodeName}
                        >
                          {option.standardCodeName}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="">
                <text>Task Name :</text>
                <div className="col-12">
                  <input
                    tabIndex={0}
                    className="col-12"
                    disabled={disableNonProject || !this.props.clickInput}
                    maxLength={30}
                    value={this.state.taskName}
                    data-toggle="tooltip"
                    data-placement="left"
                    title={this.state.taskName}
                    // alt={`${this.state.taskName===""?"Enter Task Name":this.state.taskName}`}
                    aria-label={`${
                      this.state.taskName === ""
                        ? "Enter Task Name"
                        : this.state.taskName
                    }`}
                    type="text"
                    onChange={(e) => {
                      const newText = e.target.value.replace(
                        /[^A-Za-z0-9\s]/g,
                        ""
                      );
                      this.setState({ taskName: newText });
                    }}
                  />
                </div>
                <button
                  tabIndex={0}
                  disabled={disableNonProjectTaskBtn || !this.props.clickInput}
                  type="button"
                  class="btn btn-dark"
                  onClick={this.handleNonProjectSave}
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Create Non Project Task"
                  alt="Create Non Project Task"
                >
                  Add Task
                </button>
              </div>
              <div>
                <span className="notify-label col-12">
                  (This task is valid only for the current week)
                </span>
              </div>
            </div>
          )}

          {this.state.selectProjectType === "unAssigned" &&
            this.state.unassignedProjects.length !== 0 && (
              <div className="ch-un-assigned">
                {this.state.isCopyUnassigedTaskEnabled && (
                  <div className="copy-unAssign-Task">
                     <button
                      tabIndex={0}
                      className=""
                      href="#"
                      onClick={() => {
                        this.props.copyUnassignTaskButton();
                      }}
                      data-toggle="tooltip"
                      data-placement="left"
                      title="Copy Unassigned Task to Current Week From Previous Week"
                      alt="Copy Unassigned Task to Current Week From Previous Week"
                    >
                      Copy Self-Assigned Tasks From Previous Week
                    </button>
                  </div>
                )}

                <div className="">
                  <text>Project Name :</text>

                  <div>
                    <Dropdown>
                      <Dropdown.Toggle
                        tabIndex={0}
                        variant="light"
                        id="dropdown-basic"
                        data-toggle="tooltip"
                        data-placement="left"
                        title={
                          selectedOption
                            ? selectedOption.projectName
                            : "Select an option"
                        }
                        alt={
                          selectedOption
                            ? selectedOption.projectName
                            : "Select an option"
                        }
                      >
                        {selectedOption
                          ? selectedOption.projectName
                          : "Select an option"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        style={{ maxHeight: "170px", overflow: "auto" }}
                      >
                        <FormControl
                          className="unassigned-task-search"
                          type="text"
                          placeholder="Search..."
                          value={searchValue}
                          onChange={this.handleSearchChange}
                        />
                        {filteredOptions?.map((option) => (
                          <Dropdown.Item
                            key={option.gteProjectId}
                            onClick={() => this.handleOptionSelect(option)}
                          >
                            {option.projectName}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="">
                  <text>Task Name :</text>

                  <div>
                    <Dropdown>
                      <Dropdown.Toggle
                        tabIndex={0}
                        variant="light"
                        id="dropdown-basic"
                        disabled={disable}
                        data-toggle="tooltip"
                        data-placement="left"
                        title={this.checkUnassignedTaskIsEmpty()}
                        alt={this.checkUnassignedTaskIsEmpty()}
                      >
                        {this.checkUnassignedTaskIsEmpty()}
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        style={{ maxHeight: "170px", overflowY: "auto" }}
                      >
                        <FormControl
                          className="unassigned-task-search"
                          type="text"
                          placeholder="Search..."
                          value={searchTaskValue}
                          onChange={this.handleSearchTaskChange}
                        />
                        {filteredTasks?.map((option, index) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() =>
                              this.handleOptionSelectUnassignedTask(option)
                            }
                          >
                            {option.taskName}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <button
                    tabIndex={0}
                    disabled={disable || !this.props.clickInput}
                    type="button"
                    class="btn btn-dark"
                    onClick={this.handleUnassignedTaskSave}
                    data-toggle="tooltip"
                    data-placement="left"
                    title="Create Unassign Task"
                    alt="Create Unassign Task"
                  >
                    Add Task
                  </button>
                </div>
                <div>
                  <span className="notify-label col-12">
                    (This task is valid only for the current week)
                  </span>
                </div>
              </div>
            )}
          {this.state.selectProjectType === "unAssigned" &&
            this.state.unassignedProjects.length === 0 && (
              <div className="ch-un-assigned">
                <div className="">
                  <text>Project Name :</text>

                  <div>
                    <Dropdown>
                      <Dropdown.Toggle
                        tabIndex={0}
                        variant="light"
                        id="dropdown-basic"
                        disabled
                        data-toggle="tooltip"
                        data-placement="left"
                        title={
                          selectedOption
                            ? selectedOption.projectName
                            : "Select an option"
                        }
                        alt={
                          selectedOption
                            ? selectedOption.projectName
                            : "Select an option"
                        }
                      >
                        {selectedOption
                          ? selectedOption.projectName
                          : "Select an option"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <FormControl
                          type="text"
                          placeholder="Search..."
                          value={searchValue}
                          onChange={this.handleSearchChange}
                          data-toggle="tooltip"
                          data-placement="left"
                          title={
                            selectedOption
                              ? selectedOption.projectName
                              : "Select an option"
                          }
                          alt={
                            selectedOption
                              ? selectedOption.projectName
                              : "Select an option"
                          }
                        />
                        {filteredOptions?.map((option) => (
                          <Dropdown.Item
                            key={option.gteProjectId}
                            onClick={() => this.handleOptionSelect(option)}
                          >
                            {option.projectName}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="">
                  <text>Task Name :</text>

                  <div>
                    <Dropdown>
                      <Dropdown.Toggle
                        tabIndex={0}
                        variant="light"
                        id="dropdown-basic"
                        disabled
                        data-toggle="tooltip"
                        data-placement="left"
                        title={this.checkUnassignedTaskIsEmpty()}
                        alt={this.checkUnassignedTaskIsEmpty()}
                      >
                        {/* {selectedUnassignedTask
                        ? selectedUnassignedTask?.taskName
                        : "Select an option"} */}
                        {this.checkUnassignedTaskIsEmpty()}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <FormControl
                          type="text"
                          placeholder="Search..."
                          value={searchTaskValue}
                          onChange={this.handleSearchTaskChange}
                        />
                        {filteredTasks?.map((option) => (
                          <Dropdown.Item
                            key={option.taskId}
                            onClick={() =>
                              this.handleOptionSelectUnassignedTask(option)
                            }
                          >
                            {option.taskName}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <button
                    tabIndex={0}
                    disabled
                    type="button"
                    class="btn btn-dark"
                    onClick={this.handleUnassignedTaskSave}
                    data-toggle="tooltip"
                    data-placement="left"
                    title="Create Unassign Task"
                    alt="Create Unassign Task"
                  >
                    Add Task
                  </button>
                </div>
                <div>
                  <span className="notify-label col-12">
                    (This task is valid only for the current week)
                  </span>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default AddTask;
