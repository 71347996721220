import React, { Component } from "react";
import PopOverForTimeValidation from "../commonComponents/popOverForTimeValidation";
import {
  blockInvalidChar,
  inputValidation,
  inputValidationOnBlur,
} from "../../../utils/validations";
import { inputValidationForRemainingHours } from "../../../utils/validations";
import { OverlayTrigger, ProgressBar, Tooltip } from "react-bootstrap";

class SelectedTask extends Component {
  constructor(props) {
    super(props);
    this.state = { inputValue: "" };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userTimeSheetData !== this.props.userTimeSheetData) {
    }
  }

  handleChange = (event) => {
    // Get the value from the input field
    const inputValue = event.target.value;
    const numbersOnly = inputValue.replace(/[^0-9.]/g, "");
    let result = inputValidation(numbersOnly, 5);
    // result =inputValidationOnBlur(result)
    // Update the state with the new value
    this.setState({ inputValue: result });
  };
  remainingHoursInputValidationOnBlure = () => {
    let result = this.state.inputValue;
    result = inputValidationOnBlur(result);
    this.setState({ inputValue: result });
  };

  renderInputComponent(entry) {
    return (
      <input
        tabIndex={0}
        data-toggle="tooltip"
        data-placement="left"
        title={entry?.time}
        alt={entry?.time}
        className={`timeSlot ${
          this.props.selectedTaskValue?.timedate?.istimeTypeEnableProject
            ? (entry?.timeEntryPendingStatus === "A"|| entry?.timeEntryPendingStatus === "R")?"PS":entry?.timeEntryStatus
            : ""
        } ${
          this.props.selectedTaskValue?.timedate?.isFreezed === "Y"
            ? this.props.freezeDateData?.isFreezed
              ? "quater_freeze"
              : "week_freeze"
            : ""
        }`}
        name="time"
        ref={(inputElement) => {
          if (inputElement) {
            if (entry?.timeError) {
              inputElement.select();
              inputElement.focus();
            }
            if (!entry?.timeError && entry?.isSelected) {
              inputElement.select();
            }
            if (entry?.focused) {
              inputElement.focus();
            }
          }
        }}
        disabled={
          entry?.timeEntryStatus === "B" ||
          entry?.timeEntryStatus === "A" ||
          entry?.isFreezed === "Y"
            ? true
            : false
        }
        value={entry?.time}
        onKeyUp={(e) => {
          if (blockInvalidChar.includes(e.key)) {
            e.target.value = "";
          }
        }}
        onBlur={(event) =>
          this.props.validateTimeEntered_TimeType(
            event.target.value,
            this.props.selectedTaskValue,
            entry
          )
        }
        onChange={(event) =>
          this.props.addTimeEntered_TimeType(
            event.target.value,
            this.props.selectedTaskValue,
            entry
          )
        }
        onClick={(event) => {
          this.props.highlightSelectedInputOfTimeType(
            this.props.selectedTaskValue,
            entry
          );
        }}
        onCopy={(e) => {
          e.preventDefault();
          return false;
        }}
        onPaste={(e) => {
          e.preventDefault();
          return false;
        }}
      />
    );
  }
  renderStatusForTooltip(entry) {
    return (
      <>
        {entry?.isFreezed === "Y" && (
          <>
            <strong>Time Freeze:</strong>{" "}
            <span>
              {entry?.isFreezed === "Y"
                ? this.props.freezeDateData?.isFreezed
                  ? " Quarter Time Frozen"
                  : " Weekly Time Frozen"
                : ""}
            </span>
            <br></br>
          </>
        )}
        {!(entry?.timeEntryStatus === "S" && entry?.time === 0) && (
          <>
            <strong>Time Status: </strong>{" "}
            <span>
              {(entry?.timeEntryPendingStatus === "A"|| entry?.timeEntryPendingStatus === "R")?"Time Under Review for Approval":entry?.timeEntryStatus === "U"
                ? "Time Submitted for Approval"
                : entry?.timeEntryStatus === "A"
                ? "Time Approved by Time Approver"
                : entry?.timeEntryStatus === "R"
                ? "Time Rejected by Time Approver"
                : entry?.timeEntryStatus === "B"
                ? "Cell Blocked due to the end of the Project/Allocation/Task or Task Assignment"
                : entry?.timeEntryStatus === "S" && "Time Saved"}
            </span>
          </>
        )}
      </>
    );
  }
  entering = (e) => {
    e.children[1].style.backgroundColor = "#2b7bcc";
    e.children[1].style.color = "#fff";
    e.children[1].style.borderColor = "#2b7bcc";
  };
  
  render() {
    let styleForTooltip = {
      background: "linear-gradient(0deg, #403fe2, #4a7bb6)",
      color: "#fff",
      border: "1px solid #2b7bcc",
      borderRadius: "5px",
      fontSize: "10px",
    };
    return (
      <div
        style={{
          height: "62vh",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
       
      >
        <div className="ch-selected">
          <div
            className="ch-selected-task-name"
            tabIndex={0}
            title={`${this.props.selectedTaskValue?.task?.taskName}`}
          >
            {this.props.selectedTaskValue?.task?.taskName}
          </div>
          <div>
            <div className="row ch-selected-info">
              <div
                className="ch-50col selected-lbl"
                tabIndex={0}
                alt={`Plan start date is ${this.props.selectedTaskValue?.task?.taskAssignmentStartDate}`}
              >
                <div className="">
                  <label>Plan Start</label>
                </div>
                <div className="selected-data">
                  <span>
                    {
                      this.props.selectedTaskValue?.task
                        ?.taskAssignmentStartDate
                    }
                  </span>
                </div>
              </div>
              <div
                className="ch-50col selected-lbl"
                tabIndex={0}
                alt={`Plan End date is ${this.props.selectedTaskValue.task?.taskAssignmentEndDate}`}
              >
                <div className="">
                  <label>Plan End</label>
                </div>
                <div className="selected-data">
                  <span>
                    {this.props.selectedTaskValue.task?.taskAssignmentEndDate}
                  </span>
                </div>
              </div>
            </div>

            <svg
              tabIndex={0}
              alt={`${
                this.props.selectedTaskValue.task?.estTimePersentage
                  ? this.props.selectedTaskValue.task?.estTimePersentage
                  : 0
              }%`}
              id="prgresBar"
              width="100%"
              height="16"
              style={{ display: "block", backgroundColor: "transparent" }}
            >
              <rect
                x="0"
                width="100%"
                height="16"
                fill="#cacaca"
                style={{ strokeWidth: "-1" }}
              ></rect>
              <rect
                x="0"
                y="0"
                height="16"
                fill="#e84c3d"
                width={`${
                  this.props.selectedTaskValue.task?.estTimePersentage
                    ? this.props.selectedTaskValue.task?.estTimePersentage
                    : 0
                }%`}
                style={{ strokeWidth: "0" }}
              ></rect>
              <g
                fill="#222"
                textAnchor="middle"
                fontFamily="DejaVu Sans,Verdana,Geneva,sans-serif"
                fontSize="11px"
              >
                <text x="47%" y="11.666666666666666" fill="#222">
                  {this.props.selectedTaskValue.task?.estTimePersentage
                    ? this.props.selectedTaskValue.task?.estTimePersentage
                    : 0}
                  %
                </text>
              </g>
            </svg>

            <div className="row ch-selected-info ">
              <text
                className=""
                tabIndex={0}
                alt={`Estimated Hours: ${this.props.selectedTaskValue.task?.estimatedHours} and Logged Hours: $  {this.props.selectedTaskValue.task?.loggedHours} `}
              >
                <span className="logged-hour-label-left">
                  {" "}
                  {"Est (hrs) : "}{" "}
                  {this.props.selectedTaskValue.task?.estimatedHours}
                </span>
                <span className="logged-hour-label-center"> {" | "}</span>
                <span className="logged-hour-label-right">
                  {"Logged (hrs) : "}

                  {this.props.selectedTaskValue.task?.loggedHours}
                </span>
              </text>
            </div>

            <div className="row ch-selected-info">
              <div className="ch-rem-hrs">
                <span alt="Remaining Hours">Remaining Hours :</span>
                <input
                  tabIndex={0}
                  data-toggle="tooltip"
                  data-placement="left"
                  title={`Remaining Hours ${this.state.inputValue}`}
                  alt={`Remaining Hours ${this.state.inputValue}`}
                  value={this.state.inputValue}
                  onChange={this.handleChange}
                  onBlur={() => {
                    this.remainingHoursInputValidationOnBlure();
                  }}
                />
              </div>
            </div>
            <div className="ch-selected-info">
              <div className="">
                {this.props.selectedTaskValue?.timedate
                  ?.istimeTypeEnableProject &&
                  this.props.selectedTaskValue?.timedate?.timeEntry?.map(
                    (entry) => (
                      <div className="selected-info-more">
                        <div className="row input-lbl">{entry?.timeType}</div>
                        {entry?.timeError && <PopOverForTimeValidation />}
                        <div className="row input-tag">
                          {(entry?.isChanged &&
                            (entry?.initialTime !== (entry?.time === ""?0:parseFloat(entry?.time)))) ||
                          (entry.timeEntryStatus === "S" &&
                           ( entry?.time === 0 && !(entry.isFreezed === "Y"))) ? (
                            this.renderInputComponent(entry)
                          ) : (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip
                                  id={`tooltip-${entry?.timeEntryId}`}
                                  trigger={["hover", "focus"]}
                                  className="time_status_tooltip"
                                >
                                  {this.renderStatusForTooltip(entry)}
                                </Tooltip>
                              }
                              onEntering={(e) => {
                                this.entering(e);
                              }}
                            >
                              {this.renderInputComponent(entry)}
                            </OverlayTrigger>
                          )}
                          <input
                            tabIndex={0}
                            data-toggle="tooltip"
                            data-placement="left"
                            alt={
                              entry?.invoiceComment === null ||
                              entry?.invoiceComment === "null"
                                ? ""
                                : entry?.invoiceComment
                            }
                            title={
                              entry?.invoiceComment === null ||
                              entry?.invoiceComment === "null"
                                ? ""
                                : entry?.invoiceComment
                            }
                            type="text"
                            name="entry-text"
                            maxLength={256}
                            value={
                              entry?.invoiceComment === null ||
                              entry?.invoiceComment === "null"
                                ? ""
                                : entry?.invoiceComment
                            }
                            onBlur={(event) =>
                              this.props.enterInvoiceCommentOfTimeTypeProject(
                                event.target.value,
                                this.props.selectedTaskValue,
                                entry
                              )
                            }
                            onChange={(event) =>
                              this.props.enterInvoiceCommentOfTimeTypeProject(
                                event.target.value,
                                this.props.selectedTaskValue,
                                entry
                              )
                            }
                            onClick={(event) =>
                              this.props.enterInvoiceCommentOfTimeTypeProject(
                                event.target.value,
                                this.props.selectedTaskValue,
                                entry
                              )
                            }
                          />
                        </div>
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SelectedTask;
