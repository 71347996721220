import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import warningIcon from "../../../logo/warning.png";
import { Toast } from "react-bootstrap";

class PopOverForTimeValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopover: false, // Set the initial state to hide the popover
    };
  }

  componentDidMount() {
    this.setState({ showPopover: true });
  }

  render() {
    const { showPopover } = this.state;

    const popOverForTimeValidation = (
      <Toast id="timeValidation" className="">
        <Toast.Body
          className="time-validation"
          style={{
            background: "rgb(48 119 187)",
            color: "#fff",
            border: "1px solid rgb(48 119 187)",
            borderRadius: "5px",
            padding: "5px",
            fontSize: "11px",
          }}
        >
          <img
            src={warningIcon}
            style={{ width: "25px", height: "25px", padding: "2px" }}
          />

          <span className="" style={{ paddingLeft: "5px" }}>
            Hmm, this doesn't look right. Make sure hours are under 24
          </span>
        </Toast.Body>
      </Toast>
    );

    return (
      <OverlayTrigger
        trigger="click"
        placement="top"
        overlay={popOverForTimeValidation}
        show={showPopover}
        overlayStyle={{ background: "#159784", color: "#fff" }}
      >
        <text />
      </OverlayTrigger>
    );
  }
}

export default PopOverForTimeValidation;
