import React, { Component, Fragment } from "react";
import { Toast } from "react-bootstrap";

class TaskDoneWarningModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toast: true,
    };
  
    this.lastRequestId = null;
  }

  componentDidMount() {
    // setTimeout(() => {
    //   this.setState({
    //     toast: false,
    //   });
    //   this.props.taskDone(false);
    // }, 5000);
  }
  render() {
    let modelStyle = {
      display: "block",
      backgroundColor: "rgba(0,0,0,0.8)",
    };

    return (
      <div
      tabIndex={0}
      ref={(inputElement) => {
        if (inputElement) {
          inputElement.focus();
         
        }
      }}
      className="task-done-warning"
        style={{
          // display: "flex",
          backgroundColor: "#fff",
          position: "absolute",
          top: 100,
          left: 400,
          zIndex: 9999,
          float: "left",
          border: "2px solid #cecece",
          borderRadius: "5px",
          width:"550px"
        }}
      >
        <Toast
        tabIndex={1}
         
          className=""
          style={{ width:"550px"  }}
          onClose={() => {
            this.setState({
              toast: false,
            });
            this.props.taskDone(false);
          }}
        >
          <Toast.Header >
            <div className="warning-ico"></div>
            <strong className="me-auto" tabIndex={0}> Warning</strong>
          </Toast.Header>
          <Toast.Body >
            <div className="row" style={{ fontSize: "12px",}}>
              <label tabIndex={0}>
                Changing the Checkin Task status to "Done" will freeze the time
                entry for the Task.
              </label>
            </div>
            <div className="modal-footer">
              <button
              tabIndex={0}
                type="button"
                className="btn modal-button"
                onClick={() => this.props.taskDone(false)}
              >
                OK
              </button>
            </div>
          </Toast.Body>
        </Toast>
      </div>
    );
  }
}

export default TaskDoneWarningModel;
