import React, { Component } from "react";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

class DayCard extends Component {
  renderingStatusForDayCardTooltip(day) {
    return (
      <>
        {this.props.day?.isLeave !== "N" && (
          <>
            <strong>Leave Status: </strong>{" "}
            <span>
              {this.props.day?.isLeave === "Leave - Full Day"
                ? "Full-Day Leave"
                : this.props.day?.isLeave === "Leave - Half Day"
                ? "Half-Day Leave"
                : this.props.day?.isLeave === "Leave - Hourly"
                ? "Hourly Leave"
                : ""}
            </span>
            <br></br>
          </>
        )}
        {this.props.day?.isHoliday !== "N" && (
          <>
            <strong>Holiday Status: </strong>{" "}
            <span>
              {this.props.day?.isHoliday === "Holiday - Full Day"
                ? "Full-Day Holiday"
                : this.props.day?.isHoliday === "Holiday - Half Day"
                ? "Half-Day Holiday"
                : ""}
            </span>
          </>
        )}
      </>
    );
  }

  entering = (e) => {
    e.children[1].style.backgroundColor = "#2b7bcc";
    e.children[1].style.color = "#fff";
    e.children[1].style.borderColor = "#2b7bcc";
  };

  renderingDayCardInputComponent() {
    return (
      <div
        className={`week-number ${
          this.props.day?.date === this.props.selectedDate.today ? "today" : ""
        } ${
          this.props.day?.isHoliday === "Holiday - Full Day"
            ? "Holiday_Full_Day"
            : this.props.day?.isHoliday === "Holiday - Half Day"
            ? "Holiday_Half_Day"
            : "N"
        }
    ${
      this.props.day?.isLeave === "Leave - Full Day"
        ? "Leave_Full_Day"
        : this.props.day?.isLeave === "Leave - Half Day"
        ? "Leave_Half_Day"
        : this.props.day?.isLeave === "Leave - Hourly"
        ? "Leave_Hourly"
        : "N"
    }`}
      >
        <div className="date"> {moment(this.props.day?.date).format("D")}</div>
        <div className="day">{moment(this.props.day?.date).format("dddd")}</div>
        <div className="leave-status"></div>
      </div>
    );
  }
  render() {
    return (
      <React.Fragment>
        <div
          className="ch-week-col"
          tabIndex={0}
          title={`${moment(this.props.day?.date).format("D")} ${moment(
            this.props.day?.date
          ).format("dddd")} total hours per day are  ${
            this.props.day?.timeTotal
          } Hours`}
          aria-label={`${moment(this.props.day?.date).format("D")} ${moment(
            this.props.day?.date
          ).format("dddd")} ${this.props.day?.timeTotal} Hours`}
        >
          {this.props.day?.isHoliday !== "N" ||
          this.props.day?.isLeave !== "N" ? (
            <OverlayTrigger
              id={"timeRecId: "}
              placement="top"
              trigger={["hover", "focus"]}
              overlay={
                <Tooltip id={`tooltip`} className="leaveHoliday_status_tooltip">
                  {this.renderingStatusForDayCardTooltip(this.props.day)}
                </Tooltip>
              }
              onEntering={(e) => {
                this.entering(e);
              }}
            >
              {this.renderingDayCardInputComponent()}
            </OverlayTrigger>
          ) : (
            this.renderingDayCardInputComponent()
          )}
          {/* {this.renderingDayCardInputComponent()} */}
          <div
            className={`week-hour ${this.props.day?.timeError ? "active" : ""}`}
          >
            {this.props.day?.timeTotal} Hrs
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DayCard;
