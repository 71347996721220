import React, { Component, Fragment } from "react";

class ErrorModel extends Component {
  constructor(props) {
    super(props);

    this.lastRequestId = null;
  }

  render() {
    let modelStyle = {
      display: "block",
      backgroundColor: "rgba(0,0,0,0.8)",
    };

    return (
      <Fragment>
        <div>
          <div className="modal show " style={modelStyle}>
            <div className="modal-dialog modal-width">
              <div className="modal-content">
                <div className="modal-header errorModel">
                  <span> Error </span>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                      this.props.hide();
                    }}
                  ></button>
                </div>

                <div>
                  <div className="modal-body">
                    <div className="row modal-title">
                      <div className="col-12">
                        {this.props.errorResponse?.message?.errorMessage}
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn modal-button"
                      onClick={() => this.props.hide()}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ErrorModel;
