import React, { Component, Fragment } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import FormControl from "react-bootstrap/FormControl";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { reasonsToUnfreeze } from "../../../utils/validations";
import { getErrorMessage, unFreezeMyWeek } from "../../../utils/utils";
import CheckInLoadingPopup from "./checkInLoadingPopup";

class UnfreezeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {
        status: false,
        message: "",
      },
      show: true,
      options: [],
      searchValue: "",
      selectedOption: null,
      benchOrNonProject: false,
      searchValueForUnfreeze: "",
      selectedOptionForUnfreeze: reasonsToUnfreeze[0],
      isSelectAnyOtherReason: false,
      commentOfAnyOtherReason: "",
      responseRecieved: false,
      ticketNo: "",
      isRequiredFieldMissed: false,
      loading: false,
      initialState: true,

      // isClickSubmit:false
    };
  }

  componentDidMount() {
    if (this.props.projectList?.length > 0) {
      this.setState({
        selectedOption: this.props.projectList[0],
      });
    }
    setTimeout(() => {
      this.setState({
        initialState: false,
      });
    }, 1000);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.selectedOptionForUnfreeze !==
      this.state.selectedOptionForUnfreeze
    ) {
    }
  }
  handleClose = () => {
    this.setState({ show: false });
    this.props.showUnfreezeModal(false);
  };
  handleSearchChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  handleOptionSelect = (option) => {
    this.setState({
      selectedOption: {
        gteProjectId: option?.gteProjectId,
        name: option?.name,
      },
      searchValue: "",
    });
  };
  handleCheckBoxChangeEvent = (e) => {
    this.setState({
      benchOrNonProject: e.target.checked,
    });

    if (e.target.checked) {
      this.setState({
        selectedOption: { gteProjectId: -1, name: "Non-Project" },
        searchValue: "",
      });
    } else {
      this.setState({
        selectedOption: this.props.projectList[0],
      });
    }
  };
  handleSearchChangeForUnfreeze = (e) => {
    this.setState({ searchValueForUnfreeze: e.target.value });
  };

  handleOptionSelectForUnfreeze = (option) => {
    this.setState({
      selectedOptionForUnfreeze: { key: option?.key, value: option?.value },
      searchValueForUnfreeze: "",
    });
    if (option?.key === 7) {
      this.setState({
        isSelectAnyOtherReason: true,
      });
    } else {
      if (option?.key !== 7 || option?.key !== 1) {
        this.setState({
          isRequiredFieldMissed: false,
        });
      }
      this.setState({
        isSelectAnyOtherReason: false,
        commentOfAnyOtherReason: "",
      });
    }
  };
  handleCommentOfAnyotherReaseon = (e) => {
    e.target.value = e.target.value;

    this.setState({
      commentOfAnyOtherReason: e.target.value,
      isRequiredFieldMissed: false,
    });
  };
  handleSubmitOnModel() {
    let selectedOptionForUnfreeze = this.state.selectedOptionForUnfreeze.key;

    if (selectedOptionForUnfreeze === 1) {
      this.setState({
        isRequiredFieldMissed: true,
      });
    } else if (selectedOptionForUnfreeze === 7) {
      let comment = this.state.commentOfAnyOtherReason.trim();
      if (comment.length > 0) {
        this.createResponseValue();
      } else {
        this.setState({
          isRequiredFieldMissed: true,
        });
      }
    } else {
      if (!this.state.isRequiredFieldMissed) {
        this.createResponseValue();
      }
    }
  }
  createResponseValue() {
    let value = {
      gteProjectId: parseInt(this.state.selectedOption?.gteProjectId),

      year: parseInt(this.props.selectedDate?.year),

      weekNo: parseInt(this.props.selectedDate?.week),

      gteEmployeeNo: parseInt(this.props.operationalUserGteEmployeeNo),

      reasonToUnfreeze: this.state.selectedOptionForUnfreeze?.value,

      loggedUserGteEmployeeId: parseInt(this.props.loggedUserGteEmployeeNo),
    };
    if (this.state.isSelectAnyOtherReason) {
      value.comment = this.state.commentOfAnyOtherReason;
    }
    this.saveUnFreezeMyWeekData(value);
  }
  saveUnFreezeMyWeekData(value) {
    this.setState({
      loading: true,
    });
    unFreezeMyWeek(value)
      .then((response) => {
        this.setState({
          responseRecieved: true,
          ticketNo: response?.data?.result?.number,
          loading: false,
        });
      })
      .catch((error) => {
        let message = getErrorMessage(error);
        this.setState({
          loading: false,
          error: {
            status:
              message?.status === 401
                ? false
                : message?.status === 404
                ? false
                : true,
            message: message,
          },
        });
      });
  }
  render() {
    const { show } = this.state;
    const {
      searchValue,
      selectedOption,
      searchValueForUnfreeze,
      selectedOptionForUnfreeze,
    } = this.state;

    const filteredOptions = this.props.projectList?.filter(
      (option) =>
        option?.gteProjectId !== -1 &&
        option?.name.toLowerCase().includes(searchValue.toLowerCase())
    );

    const filteredOptionsForUnfreeze = reasonsToUnfreeze?.filter((option) =>
      option?.value.toLowerCase().includes(searchValueForUnfreeze.toLowerCase())
    );
    let modelStyle = {
      display: "block",
      backgroundColor: "rgba(0,0,0,0.8)",
    };

    return (
      <Fragment>
        <div>
          <div
            className="modal show fade unfreeze-popup"
            // title="Entered unfreeze-popup"
            style={modelStyle}
            // tabIndex={0}
          >
            <div className="modal-dialog modal-width">
              <div
                className="modal-content"
                // className={`modal-content ${
                //   this.state.loading ? "loading" : ""
                // }`}
              >
                <div className="modal-header">
                  <span
                    tabIndex={0}
                    ref={(inputElement) => {
                      if (inputElement) {
                        if (this.state.show && this.state.initialState) {
                          inputElement.focus();
                        }
                        if (this.state.show && this.state.loading) {
                          inputElement.focus();
                        }
                      }
                    }}
                  >
                    {" "}
                    Unfreeze My Week
                  </span>
                  <button
                    tabIndex={0}
                    title="close"
                    disabled={this.state.loading}
                    type="button"
                    className="btn-close"
                    onClick={() => {
                      this.handleClose();
                    }}
                  ></button>
                </div>

                <div className="modal-body">
                  <div className="row unfreeze-header">
                    <div
                      className="col-8"
                      tabIndex={0}
                      title="Week To Unfreeze Time"
                    >
                      {" "}
                      Week To Unfreeze Time
                    </div>

                    <div className="col-4">
                      <div
                        className="unf-date"
                        tabIndex={0}
                        title={`${this.props.selectedDate.year} year  ${this.props.selectedDate.week} week  `}
                      >
                        {this.props.selectedDate.year}-
                        {this.props.selectedDate.week}
                      </div>
                    </div>
                  </div>

                  {!this.state.benchOrNonProject && (
                    <div className="row">
                      <div className="col-12">
                        <span>
                          <b tabIndex={0}>Project To Unfreeze</b>
                        </span>
                        <Dropdown>
                          <Dropdown.Toggle
                            disabled={
                              this.state.loading || this.state.responseRecieved
                            }
                            variant="light"
                            id="dropdown-basic"
                            tabIndex={0}
                            title={selectedOption ? selectedOption?.name : ""}
                          >
                            {selectedOption ? selectedOption?.name : ""}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <FormControl
                              className="unfreeze"
                              type="text"
                              placeholder="Search..."
                              value={searchValue}
                              onChange={this.handleSearchChange}
                              tabIndex={0}
                            />
                            {filteredOptions?.map((option) => (
                              <Dropdown.Item
                                tabIndex={0}
                                eventKey={option.gteProjectId}
                                title={option?.name}
                                key={option.gteProjectId}
                                onClick={() => this.handleOptionSelect(option)}
                              >
                                {option?.name}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  )}
                  {this.props.loggedUserGteEmployeeNo ===
                    this.props.operationalUserGteEmployeeNo && (
                    <div className="row select-type">
                      <div className="col-12">
                        <div
                          key={1}
                          className="form-check"
                          tabIndex={0}
                          title={`${this.state.benchOrNonProject} Bench Employee or No Project Allocation`}
                        >
                          <input
                            disabled={this.state.loading}
                            className="form-check-input"
                            type="checkbox"
                            checked={this.state.benchOrNonProject}
                            id="flexCheckDefault"
                            onChange={(e) => this.handleCheckBoxChangeEvent(e)}
                          />
                          <label className="form-check-label">
                            <b>Bench Employee or No Project Allocation</b>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-12">
                      <span>
                        <b tabIndex={0}>Reason To Unfreeze</b>
                      </span>
                      {this.state.selectedOptionForUnfreeze?.key === 1 && (
                        <span className="text-danger m-2" tabIndex={0}>
                          * Required
                        </span>
                      )}
                      <Dropdown>
                        <Dropdown.Toggle
                          ref={(inputElement) => {
                            if (inputElement) {
                              if (
                                !this.state.isSelectAnyOtherReason &&
                                this.state.isRequiredFieldMissed
                              ) {
                                inputElement.focus();
                              }
                            }
                          }}
                          disabled={
                            this.state.loading || this.state.responseRecieved
                          }
                          variant="light"
                          id="dropdown-basic"
                          tabIndex={0}
                          title={
                            selectedOptionForUnfreeze
                              ? selectedOptionForUnfreeze?.value
                              : ""
                          }
                        >
                          {selectedOptionForUnfreeze
                            ? selectedOptionForUnfreeze?.value
                            : ""}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <FormControl
                            className="unfreeze"
                            tabIndex={0}
                            type="text"
                            placeholder="Search..."
                            value={searchValueForUnfreeze}
                            onChange={this.handleSearchChangeForUnfreeze}
                          />
                          {filteredOptionsForUnfreeze?.map(
                            (option) =>
                              option?.key !== 1 && (
                                <Dropdown.Item
                                  eventKey={option?.key}
                                  title={option?.value}
                                  key={option?.key}
                                  onClick={() =>
                                    this.handleOptionSelectForUnfreeze(option)
                                  }
                                  tabIndex={0}
                                >
                                  {option?.value}
                                </Dropdown.Item>
                              )
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  {this.state.isSelectAnyOtherReason && (
                    <div className="row">
                      <div className="col-12">
                        <span for="commentForAnyOtherReason" tabIndex={0}>
                          <b>Please Describe</b>
                        </span>
                        {this.state.commentOfAnyOtherReason?.length === 0 && (
                          <span
                            className="text-danger m-2"
                            tabIndex={0}
                            ref={(inputElement) => {
                              if (inputElement) {
                                if (
                                  this.state.isSelectAnyOtherReason &&
                                  this.state.commentOfAnyOtherReason?.length ===
                                    0 &&
                                  this.state.isRequiredFieldMissed
                                ) {
                                  inputElement.focus();
                                }
                              }
                            }}
                          >
                            * Required
                          </span>
                        )}
                      </div>
                      <div className="col-12">
                        <textarea
                          disabled={
                            this.state.loading || this.state.responseRecieved
                          }
                          tabIndex={0}
                          className="textarea"
                          id="commentForAnyOtherReason"
                          name="commentForAnyOtherReason"
                          rows="4"
                          cols="50"
                          onChange={(e) => {
                            e.preventDefault();
                            this.handleCommentOfAnyotherReaseon(e);
                          }}
                          onBlur={(e) => {
                            e.preventDefault();
                            this.handleCommentOfAnyotherReaseon(e);
                          }}
                        ></textarea>
                      </div>
                    </div>
                  )}

                  {this.state.responseRecieved && (
                    <div
                      className="unfreeze-ticket"
                      ref={(inputElement) => {
                        if (inputElement) {
                          if (
                            !this.state.loading &&
                            this.state.responseRecieved
                          ) {
                            inputElement.focus();
                          }
                        }
                      }}
                      tabIndex={0}
                      title={`Response recieved: Your Ticket Number is ${this.state.ticketNo}`}
                    >
                      <div className="row ">
                        <div className="item col-8 mx-auto">
                          Your Ticket No is :
                        </div>
                        <div className="item col-4 mx-auto">
                          {this.state.ticketNo}
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.loading && (
                    <div
                      tabIndex={0}
                      className="unfreeze-ticket"
                      ref={(inputElement) => {
                        if (inputElement) {
                          if (this.state.show && this.state.loading) {
                            inputElement.focus();
                          }
                        }
                      }}
                    >
                      <div className="row " tabIndex={0}>
                        Please wait for a moment.. Your ticket is generating
                      </div>
                    </div>
                  )}
                </div>

                <div className="modal-footer">
                  <a
                    className="unfreeze-link"
                    tabIndex={0}
                    title="Redirect To ServiceNow"
                    target="_blank"
                    href={`https://virtusa.service-now.com/sp?id=sc_cat_item&sys_id=d5dc327bdb0518505eb551b0cf9619a5&week_start_date=${this.props.selectedDate?.firstDayofWeekForUnfreeze}`}
                  >
                    Go To Anytime
                  </a>

                  {this.state.responseRecieved ? (
                    <button
                      tabIndex={0}
                      title="Close"
                      type="button"
                      className="snow-close-btn"
                      onClick={() => this.handleClose()}
                    >
                      Close
                    </button>
                  ) : (
                    <button
                      ref={(inputElement) => {
                        if (inputElement) {
                          if (this.state.loading) {
                            inputElement.focus();
                            inputElement.click();
                          }
                        }
                      }}
                      disabled={this.state.loading}
                      tabIndex={0}
                      title="Submit Request"
                      type="button"
                      className="snow-btn"
                      onClick={() => this.handleSubmitOnModel()}
                    >
                      Submit Request
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default UnfreezeModal;
