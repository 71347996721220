import React, { Component } from "react";
import { Toast, Form } from "react-bootstrap";

class ResponseToast extends Component {
  state = {
    toast: true,
    selectedOption: "",
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        toast: false,
      });
      this.props.hide();
    }, 5000);
  }

  render() {
    return (
      <div
        style={{
          position: "absolute",
          top: 20,
          right: 10,
          zIndex: 9999,
          float: "right",
        }}
      >
        <Toast
          className="response_Toast"
          style={{
            backgroundColor: "#0e0d0d",
            color: "#ececec",
            width: "fit-content",
          }}
          onClose={() => {
            this.setState({
              toast: false,
            });
            this.props.hide();
          }}
        >
          <Toast.Body>
            {/* <div className="row"> */}
              {/* <div className="alert-ico"></div> */}
            <div className="" style={{ fontSize: "12px" }}>{this.props.responseMessage}</div>
            {/* </div> */}
          </Toast.Body>
        </Toast>
      </div>
    );
  }
}
export default ResponseToast;
