import React, { Component, Fragment } from "react";
import { Toast } from "react-bootstrap";

class ErrorMessageModelOnSubmit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toast: true,
      error: {
        status: false,
        message: "",
      },
    };
    this.lastRequestId = null;
  }

  componentDidMount() {
 
    // setTimeout(() => {
    //   this.setState({
    //     toast: false,
    //   });
    //   this.props.hide();
    // }, 10000);
  }
  render() {
    let modelStyle = {
      display: "block",
      backgroundColor: "rgba(0,0,0,0.8)",
    };

    return (
      <Fragment>
      <div
       ref={(inputElement) => {
        if (inputElement) {
          inputElement.focus();
         
        }
      }}
      tabIndex={0}
      className="error-message-warning"
        style={{
          // display: "block",
          width:"720px",
          backgroundColor: "#fff",
          position: "absolute",
          top: 100,
          left: "20%",
          zIndex: 9999,
          float: "left",
          border: "2px solid #cecece",
          borderRadius: "5px"
          
        }}
      >
        <Toast
         ref={(inputElement) => {
          if (inputElement) {
            inputElement.focus();          
          }
        }}
       
          className="error-message"
          style={{
           
            width:"720px",}}
          onClose={() => {
            this.setState({
              toast: false,
            });
            this.props.hide();
          }}
        >
          <Toast.Header >
            <div className="alert-ico"></div>
            <strong className="me-auto" tabIndex={0}>Error</strong>
          </Toast.Header>
          <Toast.Body>
            {/* <div className="row"> */}
            {/* <div className="alert-ico"></div> */}
            <div className="modal-body" style={{ fontSize: "12px" }} tabIndex={0}>
              {/* <div className="row modal-title">
                      <div className="col-8">
                        Messages for this page are listed below.
                      </div>
                    </div> */}

              {this.props.maximumHourExceedError?.error && (
                <div className="row">
                 
                    Hmm, this does not look right. The number of hours should be
                    less than 24, For {this.props.maximumHourExceedError?.errorList
                      ?.map((item) => item.date + " : " + item?.value)
                      .join(", ")}
                 
                 
                </div>
              )}
              {this.props.minimumHourExceedError?.error && (
                <div className="row">
                 
                    Whoops! Minimum hours should be entered for the following
                    days. Check again and re submit. For:  {this.props.minimumHourExceedError?.errorList
                      ?.map((item) => item.date + " : " + item?.value)
                      .join(", ")}
                 
                 
                </div>
              )}
              {this.props.invoiceCommentMissingError?.error && (
                <div className="row">
                 
                    Whoops! Invoice Comment should be entered for the following
                    days. Check again and re submit. For:  {this.props.invoiceCommentMissingError?.errorList
                      ?.map((item) => item.date)
                      .join(", ")}
                 
                 
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                 ref={(inputElement) => {
                  if (inputElement) {
                    inputElement.focus();  
                   
                  }
                }}
              tabIndex={0}
                type="button"
                className="btn modal-button"
                onClick={() => this.props.hide()}
              >
                OK
              </button>
            </div>
            {/* </div> */}
          </Toast.Body>
        </Toast>
      </div>
      </Fragment>
    );
  }
}

export default ErrorMessageModelOnSubmit;
