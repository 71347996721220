import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Calendar from "react-calendar";
// import "react-calendar/dist/Calendar.css";

class Calender extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(this.props.selectedWeekFirstDate),
      selectedDate: new Date(),
      isOpen: this.props.openCalender,
      highlightedWeek: [],
      currentDate: new Date(moment().format("YYYY-MM-DD")),
    };
    this.ExampleCustomInput = this.ExampleCustomInput.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {}

  tileClassName = ({ date }) => {
    const startDate = this.state.startDate;
    const startOfWeek = new Date(startDate);
    startOfWeek.setDate(startDate.getDate() - ((startDate.getDay() + 7) % 7)); // Set to Monday of the current week

    const isCurrentWeekDay =
      date >= startOfWeek &&
      date < new Date(startOfWeek.getTime() + 7 * 24 * 60 * 60 * 1000);
    const isWeekend = date.getDay() === 0 || date.getDay() === 6; // Sunday (0) and Saturday (6)

    return isCurrentWeekDay
      ? "highlighted-day"
      : isWeekend
      ? "weekend-day"
      : null;
  };

  handleDateChange(date) {
    this.setState({ startDate: date, selectedDate: date });
    this.props.getSelectedDate(date.toISOString().split("T")[0]);
  }

  ExampleCustomInput({ value, onClick, ref }) {
    return <label />;
  }

  render() {
    const { startDate } = this.state;

    // Get the current date
    const currntDate = new Date();

    // Calculate the number of days until the next Sunday (0 for Sunday, 1 for Monday, etc.)
    const daysUntilSunday = 7 - currntDate.getDay();

    // Calculate the date of the next Sunday by adding the daysUntilSunday to the current date
    const nextSunday = new Date(currntDate);
    nextSunday.setDate(currntDate.getDate() + daysUntilSunday);

    // Add 28 days to the next Sunday to get the desired date
    const maxDate = new Date(nextSunday);
    maxDate.setDate(nextSunday.getDate() + 28);

    const minDate = new Date("2015-01-01");

    return (
      <div>
        <style>{`
          .highlighted-day {
            background-color: #9c9595d6; /* Set your desired background color here */
            color: white; /* Set text color if needed */
          }
        `}</style>
        <Calendar
          value={startDate}
          onChange={this.handleDateChange}
          customInput={<this.ExampleCustomInput />}
          tileClassName={this.tileClassName}
          maxDate={maxDate}
          minDate={minDate}
        />
      </div>
    );
  }
}

export default Calender;
