import React, { Component, Fragment } from "react";
import CheckinLoad from "../../../logo/checkInLoad.gif";

class CheckInLoadingPopup extends Component {
  render() {
    let af_regionStyle = {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      bottom: 0,
      right: 0,
      display: "block",
      backgroundColor: "transparent",
      cursor: "wait",
    };
    let checkinLoading = {
      position: "absolute",
      width: "150px",
      height: "150px",
      top: "40%",
      left: "40%",
      display: "block",
      backgroundColor: "transparent",
      cursor: "wait",
    };
    return (
      <Fragment>
        <div>
          {this.props.initialLoading?(
            <div className="initial-loading"> </div>
          ):(
            <div className="blocking-glass-pane"> </div>
          )}
        </div>
        </Fragment>
     );
  }
}

export default CheckInLoadingPopup;
