import React, { Component } from "react";
import CheckInLogoNew from "../../logo/gte_logo.png";

class notFound extends Component {
  render() {
    return (
      <>
        <div className="ch-header">
          <div
            tabIndex={0}
            className="app-logo"
            // data-toggle="tooltip"
            // data-placement="left"
            title="CheckIn"
            alt="CheckIn Logo"
          >
            <img src={CheckInLogoNew} alt="CheckIn" />
          </div>
          {/* <div className="blank"></div> */}
          <div className="logged-user-info"></div>
        </div>
        <div className="center_nf">
          <div className="row inline-row">
            <div className="f04">
              404: The page you requested could not be found.
            </div>
            <div className="pnf">
              <a href="/" className="pnfA">Home</a>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default notFound;
