import React, { Component, Fragment } from "react";
import moment from "moment";

class Alerts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {
        status: false,
        message: "",
      },
      alerts: {},
      selectedAlert: {},
    };
  }

  componentDidMount() {
  }

  getFirstDayOfWeek(year, weekNumber) {
    const firstDayOfWeek = moment().year(year).isoWeek(weekNumber).startOf("isoWeek");
    const firstMonday = firstDayOfWeek.clone().isoWeekday(1);
    this.props.selectedDateYear(firstMonday.format("YYYY-MM-DD"));
  }

  render() {
    return (
      <div>
        <div  style={{height:"350px", overflowY: "auto" }}>
          {this.props.alertData?.alerts?.length>0?(
            <Fragment>
          <ul className="alert-list">
            {this.props.alertData?.alerts?.map((res) => (
              <li
              tabIndex={0}
                onClick={() => {
                  this.getFirstDayOfWeek(res.year, res.weekNo);
                }}
              >
               <span className="alert-point"></span>
                {res.subject}
              </li>
            ))}
            
          </ul>
          </Fragment>
          ):(<div className="alert-list"  ><li className="no-alert"
          tabIndex={0}
          data-toggle="tooltip"
          data-placement="left"
          title="No data to display"
          alt="No data to display"
          >No data to display.</li> </div>)}
        </div>
      </div>
    );
  }
}

export default Alerts;
