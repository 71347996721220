export function getAlertsMockData() {
  let data = {
    alertSummary: 4,
    alerts: [
      {
        subject: "Unused time sheet for week: 27",
        year: 2023,
        weekNo: 27,
      },
      {
        subject: "Rejected time sheet for week: 28",
        year: 2023,
        weekNo: 28,
      },
      {
        subject: "Incomplete time sheet for week: 29",
        year: 2023,
        weekNo: 29,
      },
    ],
  };
  return data;
}

export function getMockTimeSheetData() {
  let data = {
    gteProjectId: 1111,
    projectName: "CheckIn Revamp",
    taskData: [
      {
        taskId: 11111,
        taskName: "Development",
        taskAssignmnetPlanStartDate: "2023-05-22",
        taskAssignmnetPlanEndDate: "2023-05-28",
        taskAssignmentSource: "UnAssign",
        taskAssignmentStatus: "WIP",
        estimatedHours: 1400,
        loggedHours: 230,
        timeEntryDate: [
          {
            date: "2023-05-22",
            timeRecord: [
              {
                timeRecordId: 100100100,
                totalTime: 9,
                timeRecordStatus: "S",
                isFreezed: "N",
                timeEntry: [
                  {
                    timeEntryId: 5959959595,
                    timeType: "Normal",
                    time: 3,
                    invoiceComment: "This is Invoice Comment - Normal",
                    timeEntryStatus: "A",
                    isFreezed: "N",
                  },
                  // {
                  //   timeEntryId: 5959959596,
                  //   timeType: "Free Hours",
                  //   time: 3,
                  //   invoiceComment: "This is Invoice Comment - Free Hours",
                  //   timeEntryStatus: "S",
                  //   isFreezed: "N",
                  // },
                  // // {
                  //   timeEntryId: 5959959597,
                  //   timeType: "Special Time",
                  //   time: 3,
                  //   invoiceComment:
                  //     "This is Invoice Comment - Special Time",
                  //   timeEntryStatus: "S",
                  //   isFreezed: "N",
                  // },
                ],
              },
            ],
          },
          {
            date: "2023-05-23",
            timeRecord: [
              {
                timeRecordId: 100100100,
                totalTime: 0,
                timeRecordStatus: "S",
                isFreezed: "N",
                timeEntry: [
                  {
                    timeEntryId: 5959959595,
                    timeType: "Normal",
                    time: 9,
                    invoiceComment: "This is Invoice Comment - Normal",
                    timeEntryStatus: "R",
                    isFreezed: "Y",
                  },
                  // {
                  //   timeEntryId: 5959959596,
                  //   timeType: "Free Hours",
                  //   time: 0,
                  //   invoiceComment: "This is Invoice Comment - Free Hours",
                  //   timeEntryStatus: "S",
                  //   isFreezed: "N",
                  // },
                  // {
                  //   timeEntryId: 5959959597,
                  //   timeType: "Special Time",
                  //   time: 0,
                  //   invoiceComment:
                  //     "This is Invoice Comment - Special Time",
                  //   timeEntryStatus: "S",
                  //   isFreezed: "N",
                  // },
                ],
              },
            ],
          },
          {
            date: "2023-05-24",
            timeRecord: [
              {
                timeRecordId: 100100100,
                totalTime: 9,
                timeRecordStatus: "U",
                isFreezed: "N",
                timeEntry: [
                  {
                    timeEntryId: 5959959595,
                    timeType: "Normal",
                    time: 3,
                    invoiceComment: "This is Invoice Comment - Normal",
                    timeEntryStatus: "A",
                    isFreezed: "N",
                  },
                  // {
                  //   timeEntryId: 5959959596,
                  //   timeType: "Free Hours",
                  //   time: 3,
                  //   invoiceComment: "This is Invoice Comment - Free Hours",
                  //   timeEntryStatus: "S",
                  //   isFreezed: "N",
                  // },
                  // {
                  //   timeEntryId: 5959959597,
                  //   timeType: "Special Time",
                  //   time: 3,
                  //   invoiceComment:
                  //     "This is Invoice Comment - Special Time",
                  //   timeEntryStatus: "S",
                  //   isFreezed: "N",
                  // },
                ],
              },
            ],
          },
          {
            date: "2023-05-25",
            timeRecord: [
              {
                timeRecordId: 100100100,
                totalTime: 9,
                timeRecordStatus: "S",
                isFreezed: "N",
                timeEntry: [
                  {
                    timeEntryId: 5959959595,
                    timeType: "Normal",
                    time: 3,
                    invoiceComment: "This is Invoice Comment - Normal",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  // {
                  //   timeEntryId: 5959959596,
                  //   timeType: "Free Hours",
                  //   time: 3,
                  //   invoiceComment: "This is Invoice Comment - Free Hours",
                  //   timeEntryStatus: "S",
                  //   isFreezed: "N",
                  // },
                  // {
                  //   timeEntryId: 5959959597,
                  //   timeType: "Special Time",
                  //   time: 3,
                  //   invoiceComment:
                  //     "This is Invoice Comment - Special Time",
                  //   timeEntryStatus: "S",
                  //   isFreezed: "N",
                  // },
                ],
              },
            ],
          },
          {
            date: "2023-05-26",
            timeRecord: [
              {
                timeRecordId: 100100100,
                totalTime: 9,
                timeRecordStatus: "S",
                isFreezed: "N",
                timeEntry: [
                  {
                    timeEntryId: 5959959595,
                    timeType: "Normal",
                    time: 3,
                    invoiceComment: "This is Invoice Comment - Normal",
                    timeEntryStatus: "A",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959596,
                    timeType: "Free Hours",
                    time: 3,
                    invoiceComment: "This is Invoice Comment - Free Hours",
                    timeEntryStatus: "A",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959597,
                    timeType: "Special Time",
                    time: 3,
                    invoiceComment: "This is Invoice Comment - Special Time",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                ],
              },
            ],
          },
          {
            date: "2023-05-27",
            timeRecord: [
              {
                timeRecordId: 100100100,
                totalTime: 0,
                timeRecordStatus: "S",
                isFreezed: "N",
                timeEntry: [
                  {
                    timeEntryId: 5959959595,
                    timeType: "Normal",
                    time: 0,
                    invoiceComment: "This is Invoice Comment - Normal",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959596,
                    timeType: "Free Hours",
                    time: 0,
                    invoiceComment: "This is Invoice Comment - Free Hours",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959597,
                    timeType: "Special Time",
                    time: 0,
                    invoiceComment: "This is Invoice Comment - Special Time",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                ],
              },
            ],
          },

          {
            date: "2023-05-28",
            timeRecord: [
              {
                timeRecordId: 100100100,
                totalTime: 0,
                timeRecordStatus: "S",
                isFreezed: "N",
                timeEntry: [
                  {
                    timeEntryId: 5959959595,
                    timeType: "Normal",
                    time: 0,
                    invoiceComment: "This is Invoice Comment - Normal",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959596,
                    timeType: "Free Hours",
                    time: 0,
                    invoiceComment: "This is Invoice Comment - Free Hours",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959597,
                    timeType: "Special Time",
                    time: 0,
                    invoiceComment: "This is Invoice Comment - Special Time",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        taskId: 22222,
        taskName: "Design",
        taskAssignmnetPlanStartDate: "2023-05-22",
        taskAssignmnetPlanEndDate: "2023-05-28",
        taskAssignmentSource: "UnAssigned",
        taskAssignmentStatus: "WIP",
        estimatedHours: 1400,
        loggedHours: 230,
        timeEntryDate: [
          {
            date: "2023-05-22",
            timeRecord: [
              {
                timeRecordId: 100100100,
                totalTime: 9,
                timeRecordStatus: "S",
                isFreezed: "N",
                timeEntry: [
                  {
                    timeEntryId: 5959959595,
                    timeType: "Normal",
                    time: 3,
                    invoiceComment: "This is Invoice Comment - Normal",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  // {
                  //   timeEntryId: 5959959596,
                  //   timeType: "Free Hours",
                  //   time: 3,
                  //   invoiceComment: "This is Invoice Comment - Free Hours",
                  //   timeEntryStatus: "S",
                  //   isFreezed: "N",
                  // },
                  // {
                  //   timeEntryId: 5959959597,
                  //   timeType: "Special Time",
                  //   time: 3,
                  //   invoiceComment:
                  //     "This is Invoice Comment - Special Time",
                  //   timeEntryStatus: "S",
                  //   isFreezed: "N",
                  // },
                ],
              },
            ],
          },
          {
            date: "2023-05-23",
            timeRecord: [
              {
                timeRecordId: 100100100,
                totalTime: 0,
                timeRecordStatus: "S",
                isFreezed: "N",
                timeEntry: [
                  {
                    timeEntryId: 5959959595,
                    timeType: "Normal",
                    time: 0,
                    invoiceComment: "This is Invoice Comment - Normal",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959596,
                    timeType: "Free Hours",
                    time: 0,
                    invoiceComment: "This is Invoice Comment - Free Hours",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959597,
                    timeType: "Special Time",
                    time: 0,
                    invoiceComment: "This is Invoice Comment - Special Time",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                ],
              },
            ],
          },
          {
            date: "2023-05-24",
            timeRecord: [
              {
                timeRecordId: 100100100,
                totalTime: 9,
                timeRecordStatus: "S",
                isFreezed: "N",
                timeEntry: [
                  {
                    timeEntryId: 5959959595,
                    timeType: "Normal",
                    time: 3,
                    invoiceComment: "This is Invoice Comment - Normal",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959596,
                    timeType: "Free Hours",
                    time: 3,
                    invoiceComment: "This is Invoice Comment - Free Hours",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959597,
                    timeType: "Special Time",
                    time: 3,
                    invoiceComment: "This is Invoice Comment - Special Time",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                ],
              },
            ],
          },
          {
            date: "2023-05-25",
            timeRecord: [
              {
                timeRecordId: 100100100,
                totalTime: 9,
                timeRecordStatus: "S",
                isFreezed: "N",
                timeEntry: [
                  {
                    timeEntryId: 5959959595,
                    timeType: "Normal",
                    time: 3,
                    invoiceComment: "This is Invoice Comment - Normal",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959596,
                    timeType: "Free Hours",
                    time: 3,
                    invoiceComment: "This is Invoice Comment - Free Hours",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959597,
                    timeType: "Special Time",
                    time: 3,
                    invoiceComment: "This is Invoice Comment - Special Time",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                ],
              },
            ],
          },
          {
            date: "2023-05-26",
            timeRecord: [
              {
                timeRecordId: 100100100,
                totalTime: 9,
                timeRecordStatus: "S",
                isFreezed: "N",
                timeEntry: [
                  {
                    timeEntryId: 5959959595,
                    timeType: "Normal",
                    time: 3,
                    invoiceComment: "This is Invoice Comment - Normal",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959596,
                    timeType: "Free Hours",
                    time: 3,
                    invoiceComment: "This is Invoice Comment - Free Hours",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959597,
                    timeType: "Special Time",
                    time: 3,
                    invoiceComment: "This is Invoice Comment - Special Time",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                ],
              },
            ],
          },
          {
            date: "2023-05-27",
            timeRecord: [
              {
                timeRecordId: 100100100,
                totalTime: 0,
                timeRecordStatus: "S",
                isFreezed: "N",
                timeEntry: [
                  {
                    timeEntryId: 5959959595,
                    timeType: "Normal",
                    time: 0,
                    invoiceComment: "This is Invoice Comment - Normal",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959596,
                    timeType: "Free Hours",
                    time: 0,
                    invoiceComment: "This is Invoice Comment - Free Hours",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959597,
                    timeType: "Special Time",
                    time: 0,
                    invoiceComment: "This is Invoice Comment - Special Time",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                ],
              },
            ],
          },

          {
            date: "2023-05-28",
            timeRecord: [
              {
                timeRecordId: 100100100,
                totalTime: 0,
                timeRecordStatus: "S",
                isFreezed: "N",
                timeEntry: [
                  {
                    timeEntryId: 5959959595,
                    timeType: "Normal",
                    time: 0,
                    invoiceComment: "This is Invoice Comment - Normal",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959596,
                    timeType: "Free Hours",
                    time: 0,
                    invoiceComment: "This is Invoice Comment - Free Hours",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959597,
                    timeType: "Special Time",
                    time: 0,
                    invoiceComment: "This is Invoice Comment - Special Time",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        taskId: 33333,
        taskName: "Sick Time",
        taskAssignmnetPlanStartDate: "2023-05-22",
        taskAssignmnetPlanEndDate: "2023-05-28",
        taskAssignmentSource: "UnAssigned",
        taskAssignmentStatus: "WIP",
        estimatedHours: 1400,
        loggedHours: 230,
        timeEntryDate: [
          {
            date: "2023-05-22",
            timeRecord: [
              {
                timeRecordId: 100100100,
                totalTime: 9,
                timeRecordStatus: "S",
                isFreezed: "N",
                timeEntry: [
                  {
                    timeEntryId: 5959959595,
                    timeType: "Normal",
                    time: 3,
                    invoiceComment: "This is Invoice Comment - Normal",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  // {
                  //   timeEntryId: 5959959596,
                  //   timeType: "Free Hours",
                  //   time: 3,
                  //   invoiceComment: "This is Invoice Comment - Free Hours",
                  //   timeEntryStatus: "S",
                  //   isFreezed: "N",
                  // },
                  // {
                  //   timeEntryId: 5959959597,
                  //   timeType: "Special Time",
                  //   time: 3,
                  //   invoiceComment:
                  //     "This is Invoice Comment - Special Time",
                  //   timeEntryStatus: "S",
                  //   isFreezed: "N",
                  // },
                ],
              },
            ],
          },
          {
            date: "2023-05-23",
            timeRecord: [
              {
                timeRecordId: 100100100,
                totalTime: 0,
                timeRecordStatus: "S",
                isFreezed: "N",
                timeEntry: [
                  {
                    timeEntryId: 5959959595,
                    timeType: "Normal",
                    time: 0,
                    invoiceComment: "This is Invoice Comment - Normal",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959596,
                    timeType: "Free Hours",
                    time: 0,
                    invoiceComment: "This is Invoice Comment - Free Hours",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959597,
                    timeType: "Special Time",
                    time: 0,
                    invoiceComment: "This is Invoice Comment - Special Time",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                ],
              },
            ],
          },
          {
            date: "2023-05-24",
            timeRecord: [
              {
                timeRecordId: 100100100,
                totalTime: 9,
                timeRecordStatus: "S",
                isFreezed: "N",
                timeEntry: [
                  {
                    timeEntryId: 5959959595,
                    timeType: "Normal",
                    time: 3,
                    invoiceComment: "This is Invoice Comment - Normal",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959596,
                    timeType: "Free Hours",
                    time: 3,
                    invoiceComment: "This is Invoice Comment - Free Hours",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959597,
                    timeType: "Special Time",
                    time: 3,
                    invoiceComment: "This is Invoice Comment - Special Time",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                ],
              },
            ],
          },
          {
            date: "2023-05-25",
            timeRecord: [
              {
                timeRecordId: 100100100,
                totalTime: 9,
                timeRecordStatus: "S",
                isFreezed: "N",
                timeEntry: [
                  {
                    timeEntryId: 5959959595,
                    timeType: "Normal",
                    time: 3,
                    invoiceComment: "This is Invoice Comment - Normal",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959596,
                    timeType: "Free Hours",
                    time: 3,
                    invoiceComment: "This is Invoice Comment - Free Hours",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959597,
                    timeType: "Special Time",
                    time: 3,
                    invoiceComment: "This is Invoice Comment - Special Time",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                ],
              },
            ],
          },
          {
            date: "2023-05-26",
            timeRecord: [
              {
                timeRecordId: 100100100,
                totalTime: 9,
                timeRecordStatus: "S",
                isFreezed: "N",
                timeEntry: [
                  {
                    timeEntryId: 5959959595,
                    timeType: "Normal",
                    time: 3,
                    invoiceComment: "This is Invoice Comment - Normal",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959596,
                    timeType: "Free Hours",
                    time: 3,
                    invoiceComment: "This is Invoice Comment - Free Hours",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959597,
                    timeType: "Special Time",
                    time: 3,
                    invoiceComment: "This is Invoice Comment - Special Time",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                ],
              },
            ],
          },
          {
            date: "2023-05-27",
            timeRecord: [
              {
                timeRecordId: 100100100,
                totalTime: 0,
                timeRecordStatus: "S",
                isFreezed: "N",
                timeEntry: [
                  {
                    timeEntryId: 5959959595,
                    timeType: "Normal",
                    time: 0,
                    invoiceComment: "This is Invoice Comment - Normal",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959596,
                    timeType: "Free Hours",
                    time: 0,
                    invoiceComment: "This is Invoice Comment - Free Hours",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959597,
                    timeType: "Special Time",
                    time: 0,
                    invoiceComment: "This is Invoice Comment - Special Time",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                ],
              },
            ],
          },

          {
            date: "2023-05-28",
            timeRecord: [
              {
                timeRecordId: 100100100,
                totalTime: 0,
                timeRecordStatus: "S",
                isFreezed: "N",
                timeEntry: [
                  {
                    timeEntryId: 5959959595,
                    timeType: "Normal",
                    time: 0,
                    invoiceComment: "This is Invoice Comment - Normal",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959596,
                    timeType: "Free Hours",
                    time: 0,
                    invoiceComment: "This is Invoice Comment - Free Hours",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                  {
                    timeEntryId: 5959959597,
                    timeType: "Special Time",
                    time: 0,
                    invoiceComment: "This is Invoice Comment - Special Time",
                    timeEntryStatus: "S",
                    isFreezed: "N",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };
  return data;
}

export function getMockUserTimeSheetData() {
  let data = {
    userTimeSheetData: [
      {
        gteProjectId: 1111,
        projectName: "CheckIn Revamp",
        taskData: [
          {
            taskId: 11111,
            taskName: "Development",
            taskAssignmnetPlanStartDate: "2023-05-22",
            taskAssignmnetPlanEndDate: "2023-05-28",
            taskAssignmentSource: "UnAssign",
            taskAssignmentStatus: "WIP",
            estimatedHours: 1400,
            loggedHours: 230,
            timeEntryDate: [
              {
                date: "2023-05-22",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 9,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "A",
                        isFreezed: "N",
                      },
                      // {
                      //   timeEntryId: 5959959596,
                      //   timeType: "Free Hours",
                      //   time: 3,
                      //   invoiceComment: "This is Invoice Comment - Free Hours",
                      //   timeEntryStatus: "S",
                      //   isFreezed: "N",
                      // },
                      // // {
                      //   timeEntryId: 5959959597,
                      //   timeType: "Special Time",
                      //   time: 3,
                      //   invoiceComment:
                      //     "This is Invoice Comment - Special Time",
                      //   timeEntryStatus: "S",
                      //   isFreezed: "N",
                      // },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-23",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 0,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 9,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "R",
                        isFreezed: "Y",
                      },
                      // {
                      //   timeEntryId: 5959959596,
                      //   timeType: "Free Hours",
                      //   time: 0,
                      //   invoiceComment: "This is Invoice Comment - Free Hours",
                      //   timeEntryStatus: "S",
                      //   isFreezed: "N",
                      // },
                      // {
                      //   timeEntryId: 5959959597,
                      //   timeType: "Special Time",
                      //   time: 0,
                      //   invoiceComment:
                      //     "This is Invoice Comment - Special Time",
                      //   timeEntryStatus: "S",
                      //   isFreezed: "N",
                      // },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-24",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 9,
                    timeRecordStatus: "U",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "A",
                        isFreezed: "N",
                      },
                      // {
                      //   timeEntryId: 5959959596,
                      //   timeType: "Free Hours",
                      //   time: 3,
                      //   invoiceComment: "This is Invoice Comment - Free Hours",
                      //   timeEntryStatus: "S",
                      //   isFreezed: "N",
                      // },
                      // {
                      //   timeEntryId: 5959959597,
                      //   timeType: "Special Time",
                      //   time: 3,
                      //   invoiceComment:
                      //     "This is Invoice Comment - Special Time",
                      //   timeEntryStatus: "S",
                      //   isFreezed: "N",
                      // },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-25",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 9,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      // {
                      //   timeEntryId: 5959959596,
                      //   timeType: "Free Hours",
                      //   time: 3,
                      //   invoiceComment: "This is Invoice Comment - Free Hours",
                      //   timeEntryStatus: "S",
                      //   isFreezed: "N",
                      // },
                      // {
                      //   timeEntryId: 5959959597,
                      //   timeType: "Special Time",
                      //   time: 3,
                      //   invoiceComment:
                      //     "This is Invoice Comment - Special Time",
                      //   timeEntryStatus: "S",
                      //   isFreezed: "N",
                      // },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-26",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 9,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "A",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "A",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 3,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-27",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 0,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 0,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },

              {
                date: "2023-05-28",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 0,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 0,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            taskId: 22222,
            taskName: "Design",
            taskAssignmnetPlanStartDate: "2023-05-22",
            taskAssignmnetPlanEndDate: "2023-05-28",
            taskAssignmentSource: "UnAssigned",
            taskAssignmentStatus: "WIP",
            estimatedHours: 1400,
            loggedHours: 230,
            timeEntryDate: [
              {
                date: "2023-05-22",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 9,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      // {
                      //   timeEntryId: 5959959596,
                      //   timeType: "Free Hours",
                      //   time: 3,
                      //   invoiceComment: "This is Invoice Comment - Free Hours",
                      //   timeEntryStatus: "S",
                      //   isFreezed: "N",
                      // },
                      // {
                      //   timeEntryId: 5959959597,
                      //   timeType: "Special Time",
                      //   time: 3,
                      //   invoiceComment:
                      //     "This is Invoice Comment - Special Time",
                      //   timeEntryStatus: "S",
                      //   isFreezed: "N",
                      // },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-23",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 0,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 0,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-24",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 9,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 3,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-25",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 9,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 3,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-26",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 9,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 3,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-27",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 0,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 0,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },

              {
                date: "2023-05-28",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 0,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 0,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            taskId: 33333,
            taskName: "Sick Time",
            taskAssignmnetPlanStartDate: "2023-05-22",
            taskAssignmnetPlanEndDate: "2023-05-28",
            taskAssignmentSource: "UnAssigned",
            taskAssignmentStatus: "WIP",
            estimatedHours: 1400,
            loggedHours: 230,
            timeEntryDate: [
              {
                date: "2023-05-22",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 9,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      // {
                      //   timeEntryId: 5959959596,
                      //   timeType: "Free Hours",
                      //   time: 3,
                      //   invoiceComment: "This is Invoice Comment - Free Hours",
                      //   timeEntryStatus: "S",
                      //   isFreezed: "N",
                      // },
                      // {
                      //   timeEntryId: 5959959597,
                      //   timeType: "Special Time",
                      //   time: 3,
                      //   invoiceComment:
                      //     "This is Invoice Comment - Special Time",
                      //   timeEntryStatus: "S",
                      //   isFreezed: "N",
                      // },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-23",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 0,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 0,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-24",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 9,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 3,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-25",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 9,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 3,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-26",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 9,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 3,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-27",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 0,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 0,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },

              {
                date: "2023-05-28",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 0,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 0,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        gteProjectId: 2222,
        projectName: "CheckIn RFS",
        taskData: [
          {
            taskId: 11111,
            taskName: "Development",
            taskAssignmnetPlanStartDate: "2023-05-22",
            taskAssignmnetPlanEndDate: "2023-05-28",
            taskAssignmentSource: "Assigned",
            taskAssignmentStatus: "WIP",
            estimatedHours: 1400,
            loggedHours: 230,
            timeEntryDate: [
              {
                date: "2023-05-22",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 9,
                    timeRecordStatus: "B",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 3,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-23",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 0,
                    timeRecordStatus: "B",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 0,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-24",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 9,
                    timeRecordStatus: "B",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 3,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-25",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 9,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 3,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-26",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 9,
                    timeRecordStatus: "S",
                    isFreezed: "Y",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 3,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-27",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 0,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 0,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },

              {
                date: "2023-05-28",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 0,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 0,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            taskId: 22222,
            taskName: "Design",
            taskAssignmnetPlanStartDate: "2023-05-22",
            taskAssignmnetPlanEndDate: "2023-05-28",
            taskAssignmentSource: "Assigned",
            taskAssignmentStatus: "WIP",
            estimatedHours: 1400,
            loggedHours: 230,
            timeEntryDate: [
              {
                date: "2023-05-22",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 9,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "A",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 3,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-23",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 0,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 0,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-24",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 9,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 3,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-25",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 9,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 3,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-26",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 9,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 3,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-27",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 0,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 0,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },

              {
                date: "2023-05-28",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 0,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 0,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            taskId: 33333,
            taskName: "Code Review",
            taskAssignmnetPlanStartDate: "2023-05-22",
            taskAssignmnetPlanEndDate: "2023-05-28",
            taskAssignmentSource: "UnAssigned",
            taskAssignmentStatus: "WIP",
            estimatedHours: 1400,
            loggedHours: 230,
            timeEntryDate: [
              {
                date: "2023-05-22",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 9,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      // {
                      //   timeEntryId: 5959959596,
                      //   timeType: "Free Hours",
                      //   time: 3,
                      //   invoiceComment: "This is Invoice Comment - Free Hours",
                      //   timeEntryStatus: "S",
                      //   isFreezed: "N",
                      // },
                      // {
                      //   timeEntryId: 5959959597,
                      //   timeType: "Special Time",
                      //   time: 3,
                      //   invoiceComment:
                      //     "This is Invoice Comment - Special Time",
                      //   timeEntryStatus: "S",
                      //   isFreezed: "N",
                      // },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-23",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 0,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 0,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-24",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 9,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 3,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-25",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 9,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 3,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-26",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 9,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 3,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-27",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 0,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 0,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },

              {
                date: "2023-05-28",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 0,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 0,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            taskId: 44444,
            taskName: "Sick Time",
            taskAssignmnetPlanStartDate: "2023-05-22",
            taskAssignmnetPlanEndDate: "2023-05-28",
            taskAssignmentSource: "UnAssigned",
            taskAssignmentStatus: "WIP",
            estimatedHours: 1400,
            loggedHours: 230,
            timeEntryDate: [
              {
                date: "2023-05-22",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 9,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      // {
                      //   timeEntryId: 5959959596,
                      //   timeType: "Free Hours",
                      //   time: 3,
                      //   invoiceComment: "This is Invoice Comment - Free Hours",
                      //   timeEntryStatus: "S",
                      //   isFreezed: "N",
                      // },
                      // {
                      //   timeEntryId: 5959959597,
                      //   timeType: "Special Time",
                      //   time: 3,
                      //   invoiceComment:
                      //     "This is Invoice Comment - Special Time",
                      //   timeEntryStatus: "S",
                      //   isFreezed: "N",
                      // },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-23",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 0,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 0,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-24",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 9,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 3,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-25",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 9,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 3,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-26",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 9,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 3,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 3,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
              {
                date: "2023-05-27",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 0,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 0,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },

              {
                date: "2023-05-28",
                timeRecord: [
                  {
                    timeRecordId: 100100100,
                    totalTime: 0,
                    timeRecordStatus: "S",
                    isFreezed: "N",
                    timeEntry: [
                      {
                        timeEntryId: 5959959595,
                        timeType: "Normal",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Normal",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959596,
                        timeType: "Free Hours",
                        time: 0,
                        invoiceComment: "This is Invoice Comment - Free Hours",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                      {
                        timeEntryId: 5959959597,
                        timeType: "Special Time",
                        time: 0,
                        invoiceComment:
                          "This is Invoice Comment - Special Time",
                        timeEntryStatus: "S",
                        isFreezed: "N",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  return data;
}

export function getMockNonProjectTask() {
  let data = [
    {
      standardCodeId: 116,
      processTemplate: "Maintenance PT",
      activityCode: "ACTY183",
      standardCode: "MREQ - CUT",
      standardCodeName: "Coding and Dev Test",
      isGlobal: "N",
      attribute1: null,
      attribute2: null,
      attribute3: null,
      attribute4: null,
      attribute5: null,
      attribute6: null,
      attribute7: null,
      attribute8: null,
      attribute9: null,
      attribute10: null,
      attribute11: null,
      attribute12: null,
      attribute13: null,
      attribute14: null,
      attribute15: null,
      createdBy: -1,
      createdDate: "2016-07-08T11:30:31.000+00:00",
      createdSec: 20160708113031,
      lastUpdatedBy: -1,
      lastUpdatedDate: "2016-07-08T11:30:31.000+00:00",
      lastUpdatedSec: 20160708113031,
    },
  ];

  return data;
}

export function getMockUnassignedTasks() {
  let data = {
    unassignedTasks: [
      {
        gteProjectId: 1111,
        projectName: "CheckIn Revamp",
        tasks: [
          {
            taskId: 10000,
            taskName: "Development",
          },
          {
            taskId: 10001,
            taskName: "Unit Test",
          },
        ],
      },
      {
        gteProjectId: 2222,
        projectName: "Sales Enablement",
        tasks: [
          {
            taskId: 3990,
            taskName: "Change Management",
          },
          {
            taskId: 2444,
            taskName: "Defect Review",
          },
          {
            taskId: 12333,
            taskName: "Analysis",
          },
        ],
      },
      {
        gteProjectId: 3333,
        projectName: "Clover Business Analytics",
        tasks: [
          {
            taskId: 3990,
            taskName: "Deployment Build",
          },
          {
            taskId: 2444,
            taskName: "Defect Review",
          },
          {
            taskId: 12333,
            taskName: "DMSC Audit",
          },
        ],
      },
    ],
  };

  return data;
}

export function getMockProjectList() {
  let data = [
    {
      gteProjectId: 1111,
      name: "CheckIn Revamp",
      projectType: "IMS PT",
      projectStartDate: "2015-11-06T00:00:00.000+00:00",
      projectEndDate: "2022-09-30T00:00:00.000+00:00",
      projectNo: "206757",
      extSource: "SWIFT",
      extSystemProjectId: 60291,
      attribute1: null,
      attribute2: null,
      attribute3: null,
      attribute4: null,
      attribute5: null,
      attribute6: null,
      attribute7: null,
      attribute8: null,
      attribute9: null,
      attribute10: null,
      attribute11: null,
      attribute12: null,
      attribute13: null,
      attribute14: null,
      attribute15: null,
      createdby: null,
      createdDate: null,
      createdSec: null,
      lastUpdatedBy: null,
      lastUpdatedDate: null,
      lastUpdateSec: null,
      leadBy: "Client Led",
      projectConfig: null,
      projectAssignments: null,
    },
    {
      gteProjectId: 2222,
      name: "Sales Enablement",
      projectType: "IMS PT",
      projectStartDate: "2015-11-06T00:00:00.000+00:00",
      projectEndDate: "2022-09-30T00:00:00.000+00:00",
      projectNo: "206757",
      extSource: "SWIFT",
      extSystemProjectId: 60291,
      attribute1: null,
      attribute2: null,
      attribute3: null,
      attribute4: null,
      attribute5: null,
      attribute6: null,
      attribute7: null,
      attribute8: null,
      attribute9: null,
      attribute10: null,
      attribute11: null,
      attribute12: null,
      attribute13: null,
      attribute14: null,
      attribute15: null,
      createdby: null,
      createdDate: null,
      createdSec: null,
      lastUpdatedBy: null,
      lastUpdatedDate: null,
      lastUpdateSec: null,
      leadBy: "Client Led",
      projectConfig: null,
      projectAssignments: null,
    },
    {
      gteProjectId: 3333,
      name: "Clover Business Analytics",
      projectType: "Clover Business Analytics",
      projectStartDate: "2015-11-06T00:00:00.000+00:00",
      projectEndDate: "2022-09-30T00:00:00.000+00:00",
      projectNo: "206757",
      extSource: "SWIFT",
      extSystemProjectId: 60291,
      attribute1: null,
      attribute2: null,
      attribute3: null,
      attribute4: null,
      attribute5: null,
      attribute6: null,
      attribute7: null,
      attribute8: null,
      attribute9: null,
      attribute10: null,
      attribute11: null,
      attribute12: null,
      attribute13: null,
      attribute14: null,
      attribute15: null,
      createdby: null,
      createdDate: null,
      createdSec: null,
      lastUpdatedBy: null,
      lastUpdatedDate: null,
      lastUpdateSec: null,
      leadBy: "Client Led",
      projectConfig: null,
      projectAssignments: null,
    },
  ];

  return data;
}

export function getMockDelegetedUsers() {
  let data = [
    { operationalUserGteEmployeeNo: 1000, delegatedUserName: "Dilshan" },
    { operationalUserGteEmployeeNo: 1001, delegatedUserName: "Janaka" },
    { operationalUserGteEmployeeNo: 1002, delegatedUserName: "Kasuni" },
    { operationalUserGteEmployeeNo: 1003, delegatedUserName: "Limesha" },
  ];

  return data;
}
export function getMockDayWiseTotal() {
  let data = [
    {
      date: "2023-05-15",
      dayTotal: 45.0,
    },
    {
      date: "2023-05-16",
      dayTotal: 0.0,
    },
    {
      date: "2023-05-17",
      dayTotal: 45.0,
    },
    {
      date: "2023-05-18",
      dayTotal: 45.0,
    },
    {
      date: "2023-05-19",
      dayTotal: 0.0,
    },
    {
      date: "2023-05-20",
      dayTotal: 0.0,
    },
    {
      date: "2023-05-21",
      dayTotal: 45.0,
    },
  ];

  return data;
}
